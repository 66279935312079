import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { GenericHttpResponse } from '../models/generic-http-response.model';
import { JwtResponse } from '../models/jwt-response.model';
import { AuthenticationRequest, AuthenticationRequests } from '../models/authentication-request.model';
import { ForgotPasswordRequest } from '../models/forgot-password-request.model';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { yangoosStorageKeys } from '../constant/app.constant';
import { AlertService } from 'src/app/core/service/alert.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractRestService {

  // public currentUser: Observable<User>;
  readonly baseUrl: string = environment.yangoosApiUrl + '/api';
  isLoggedIn: any;

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService,
    private localStorageService: LocalStorageService,
    // private principalService: Principal,
    private router: Router,
    // private userService: UserService,
    private alertService: AlertService,
    // private store: Store,
    private http: HttpClient
  ) {
    super(httpClient, loadingBarService);
  }


  login(payload: AuthenticationRequest, noLoadingMask = false): Observable<GenericHttpResponse<JwtResponse>> {
    return this.httpPost({
      url: this.baseUrl + '/auth/signin',
      payload: payload,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<JwtResponse>(JwtResponse),
    }) as Observable<GenericHttpResponse<JwtResponse>>;

  }

  logouts(payload: AuthenticationRequests, noLoadingMask = false): Observable<GenericHttpResponse<JwtResponse>> {
    return this.httpPost({
      url: this.baseUrl + '/auth/signout',
      payload: payload,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<JwtResponse>(JwtResponse),
    }) as Observable<GenericHttpResponse<JwtResponse>>;

  }

  refreshToken(refreshToken,noLoadingMask = false): Observable<any> {
    return this.httpPost({
      url: this.baseUrl + '/auth/RefreshToken',
      noLoadingMask: noLoadingMask,
      payload:refreshToken,    
    }) as Observable<any>;

  }

  forgotPassword(payload: ForgotPasswordRequest, noLoadingMask = false): Observable<GenericHttpResponse<string>> {
    return this.httpPost({
      url: this.baseUrl + '/auth/forgotPassword',
      payload: payload,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<String>(String),
    }) as Observable<GenericHttpResponse<string>>;

  }

  logout() {
    // Local storage    
    this.localStorageService.clear(yangoosStorageKeys.authenticationToken);
    this.localStorageService.clear(yangoosStorageKeys.user);
    this.localStorageService.clear(yangoosStorageKeys.company);
    this.localStorageService.clear(yangoosStorageKeys.store);
    this.localStorageService.clear(yangoosStorageKeys.rememberMe);
    this.localStorageService.clear(yangoosStorageKeys.companylist);
    this.localStorageService.clear(yangoosStorageKeys.storelist);
    this.localStorageService.clear(yangoosStorageKeys.sale);
    this.localStorageService.clear(yangoosStorageKeys.config);
    this.localStorageService.clear(yangoosStorageKeys.date);

    // this.store.dispatch(new Logout());
    // this.principalService.authenticate(null);
    this.successLogout();
  }

  private showSuccessMessage(message) {
    this.alertService.successAlert(message);
  }


  successLogout() {
    this.router.navigate(['/auth/signin']);
  }

  // async getCurrentUser(): Promise<User> {
  //   let user: User = undefined;
  //   // await firstValueFrom(this.userService.getCurrentLoginUser()).then(res => {      
  //   //   user = res.responseObject;      
  //   // }, .
  //   catchError => {
  //   });
  //   if (user) {
  //     return user;
  //   }
  //   return user;
  // }

  setAuthenticationInfo(jwtResponse: JwtResponse): void {
    this.localStorageService.store(yangoosStorageKeys.authenticationToken, jwtResponse.token);
    // this.localStorageService.store(yangoosStorageKeys.user, jwtResponse.user);
    this.localStorageService.store(yangoosStorageKeys.rememberMe, jwtResponse.rememberMe);
  }

  
  getAuthToken(): string {
    return this.localStorageService.retrieve(yangoosStorageKeys.authenticationToken);
  }

  getRememberMe(): boolean {
    return this.localStorageService.retrieve(yangoosStorageKeys.rememberMe) as boolean;
  }

  

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }


 }