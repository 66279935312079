<!--footer section -->
<footer [class]="class">
   
    <section>
        <div class="row mx-sm-3 ">
            <div class="footer-logo col-lg col-md-4 col-12 col-sm-6 text-center" *ngIf="storeDetails">
                <img [src]="storeDetails?.company?.logoAttachment?.data" class="img-fluid logo" alt="logo"
                    style="width: 17rem;">
            </div>
            <div class="col-lg col-md-4 col-sm-6 col-10 mx-auto p-2 text-left">
                <div>
                    <p class="mt-2 fw-bold text-black mb-0">Address</p>
                    <ul class="d-grid gap-2  ">
                        <li class="pb-2" *ngIf="storeDetails">
                            {{storeDetails?.address?.address}}{{storeDetails?.address?.city}}-{{storeDetails?.address?.postCode}}
                        </li>
                        <li class="pb-2"><span class="fw-bold">Contact Us :
                            </span>{{storeDetails?.contactDetail?.contactNumber}}</li>
                        <li class=""><span class="fw-bold">Email Us : </span>
                            <a>{{storeDetails?.contactDetail?.email}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg col-md-4 col-12 col-sm-12 p-3 justify-content-center">
                <div>
                    <p class="text-center">Transform your living space with handcrafted pieces from our Furniture Mart,
                        where elegance meets functionality</p>
                    <div class="footer-social d-flex justify-content-center m-0">
                        <ul class="d-flex" style="list-style: none; padding: 0; text-align: center;">
                            <li style="margin: 3px 5px;">
                                <a
                                    href="https://www.facebook.com/yangoostechnologies?paipv=0&eav=AfY15iF2H5huVcZTs7Yg3dTUQFnPcHGA-vF2UspuG2OkrvSPQtc4j5yANe_J2oFZ0Mk">
                                    <i class="fab fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li style="margin: 3px 5px;">
                                <a href="https://www.youtube.com/embed/Lki37cwlQvM">
                                    <i class="fab fa-youtube" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li style="margin: 3px 5px;">
                                <a href="https://yangoos.com/">
                                    <i class="fab fa-google" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li style="margin: 3px 5px;">
                                <a href="https://www.instagram.com/yangoostechnologies/?next=%2F">
                                    <i class="fab fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>  
    <div class="sub-footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <div class="footer-end">
                        <p style="text-align: center;"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today |
                            date:'y'}} powered by {{storeDetails?.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->