// spinner.service.ts
import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {
  private showSpinner = false;

  show(): void {
    this.showSpinner = true;
  }

  hide(): void {
    this.showSpinner = false;
  }

  isShowing(): boolean {
    return this.showSpinner;
  }
}
