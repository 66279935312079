import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AbstractRestService } from "./abstract-rest.service";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { GenericHttpResponse } from "../../core/models/generic-http-response.model";
import { Logout, LogoutCreate } from "../models/logout.model";
@Injectable({
  providedIn: "root",
})
export class LogoutService extends AbstractRestService {
  readonly baseUrl: string = environment.yangoosApiUrl + "/api";

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }

  logouts(
    payload: LogoutCreate,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<Logout>> {
    return this.httpPost({
      url: this.baseUrl + "/auth/signout",
      payload: payload,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Logout>(Logout),
    }) as Observable<GenericHttpResponse<Logout>>;
  }
}
