<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <div class="custom-spinner">
        <div class="spinner-circle"></div>
    </div>
</ngx-spinner>

<ngx-loading-bar></ngx-loading-bar>
<!-- <app-header-one class="sticky" [sticky]="true"></app-header-one> -->
<router-outlet></router-outlet>
<!-- <app-footer-one></app-footer-one> -->
<app-tap-to-top></app-tap-to-top>
<!-- <app-layout-box></app-layout-box> -->