import {
  AbstractResponse
} from "./abstract-response.model";

export class ItemCategory extends AbstractResponse {
  id: string;
  name: string;
}

export class StandardCategory extends AbstractResponse {
  description: string;
  id: string;
  name: string;
}

export class StoreCategory extends AbstractResponse {
  address: {
    address: string;
    city: string;
    createdBy: string;
    createdDateTime: string;
    id: string;
    postCode: string;
  };
  contactDetail: {
    contactNumber: string;
    email: string;
  };

  id: string;
  name: string;
}

export class TopCategory {
  categoryId: string;
  categoryName: string;
  total: number;
  storeId: string;
}
