import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable } from 'rxjs';
import { GenericHttpResponse } from '../../core/models/generic-http-response.model';
import { Login } from '../models/login.model';
import { RegisterCreate } from '../models/register.model';
import { environment } from './../../../environments/environment';
import { AbstractRestService } from './abstract-rest.service';
@Injectable({
  providedIn: 'root'
})
export class RegisterService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + '/api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }

  save(create: RegisterCreate, noLoadingMask = false): Observable<GenericHttpResponse<Login>> {
    return this.httpPost({
      url: `${this.baseUrl}/user/signUp`,
      payload: create,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Login>(Login),
    }) as Observable<GenericHttpResponse<Login>>;

  }

}
