import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { environment } from './../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Login, LoginCreate } from '../models/login.model';
@Injectable({
  providedIn: 'root'
})
export class LoginService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + '/api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }


loginStatus :Subject<boolean>= new Subject<boolean>();

  save(create: LoginCreate, noLoadingMask = false): Observable<Login> {
    return this.httpPost({
      url: `${this.baseUrl}/auth/signin`,
      payload: create,
      noLoadingMask: noLoadingMask,
      returnType: new Login,
    }) as Observable<Login>;

  }


}
