import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {


 myCartLengths:number;
  constructor() {
    
   }

  ngOnInit(): void {


  }


 

  receivedMyCartLength(data){

    this.myCartLengths=data;

  }

}
