import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../service/auth.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { yangoosStorageKeys } from "../constant/app.constant";
import { Principal } from "../service/principal.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private principal: Principal,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  verifyEmail() {
    // const modalRef = this.modalService.dismissAll(LoginComponent);
  }

  refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    const body = {
      refreshToken: refreshToken,
    };

    this.authService.refreshToken(body, false).subscribe({
      next: (res: any) => {
        localStorage.setItem(
          yangoosStorageKeys.authenticationToken,
          res.accessToken
        );
        localStorage.setItem("refreshToken", res.refreshToken);
      },
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authorities = route.data["authorities"];
    // const user = this.authService.getCurrentUserFromStorage();

    // If password is expired then force user to change the password
    // if (user && !!user.isPasswordExpired) {
    //   this.router.navigate(['/auth/change-password']);
    // }

    // We need to call the checkLogin / and so the principal.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.

    if (this.authService.getAuthToken()) {
      return this.checkLogin(authorities, state.url);
    } else {
      this.router.navigate(["/auth/signin"]);
    }
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {
    const principal = this.principal;

    return Promise.resolve(
      principal.identity().then((account) => {
        if (account) {
          /* return principal.hasAnyAuthority(authorities).then(response => {
               if (response) {
                   return true;
               }
               return false;
           });*/
          return true;
        }

        //this.stateStorageService.storeUrl(url);
        this.router.navigate(["/auth/access-denied"]).then(() => {
          if (!account) {
            this.router.navigate(["/auth/signin"]);
          }
        });
        return false;
      })
    );
  }
  // async canActivateChild(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Promise<boolean> {
  //   //const currentUser = await this.authService.getUser();
  //   // if (currentUser) {

  //   //   if (route.data && route.data.roles) {
  //   //     if (currentUser && currentUser.roles && currentUser.roles.find(x => route.data.roles.includes(x.name))) {
  //   //       return true;
  //   //     } else {
  //   //       this.router.navigate(['/unauthorized']);
  //   //       return false;
  //   //     }
  //   //   } else {
  //   //     return true;
  //   //   }
  //   // } else {
  //   //   this.router.navigate(['/auth/signin']);
  //   //   return false;
  //   // }
  //   return true;
  // }
  // async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

  //   //const currentUser = await this.authService.getUser();
  //   // if (currentUser) {
  //   //   if (route.data && route.data.roles) {
  //   //     if (currentUser && currentUser.roles && currentUser.roles.find(x => route.data.roles.includes(x.name))) {
  //   //       return true;
  //   //     } else {
  //   //       this.router.navigate(['/unauthorized']);
  //   //       return false;
  //   //     }
  //   //   } else {
  //   //     return true;
  //   //   }
  //   // } else {
  //   //   this.router.navigate(['/auth/signin']);
  //   //   return false;
  //   // }
  //   return true;
  // }
}
