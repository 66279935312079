<ng-template #template let-modal>
  <div class="row no-gutters">
    <div class="col-md-6 col-12">
      <img src="../../../../assets/images/login.png" style="
          width: 100%;
          border-bottom-left-radius: 25px;
          border-top-left-radius: 25px;
        " alt="login" class="login-card-img h-100" />
    </div>
    <div class="col-md-6 col-12" style="margin-top: auto; margin-bottom: auto; padding: 25px">
      <div class="mx-auto text-center">
        <img [src]="storeDetails?.company.logoAttachment?.data" style="width: 68%" alt="logo" class="logo mx-auto" />
      </div>

      <br />
      <br />
      <form class="validate-form" [formGroup]="loginForm">
        <div style="display: flex; flex-direction: column">
          <input type="text" class="form-control" style="
              border-top: none;
              border-left: none;
              border-right: none;
              width: 96%;
              font-size: 12px;
            " formControlName="email" placeholder="User Id" tabindex="1" autofocus />

          <div class="error-form" *ngIf="
              loginForm.get('email').hasError('required') &&
              loginForm.get('email').touched
            ">
            Email is required.
          </div>
        </div>

        <br />

        <div style="display: flex; flex-direction: column">
          <span><input id="passwordInput" type="password" class="form-control" formControlName="password" tabindex="2"
              style="
                border-top: none;
                border-left: none;
                border-right: none;
                width: 96%;
                font-size: 12px;
              " placeholder="Password" /></span>
          <span>
            <div class="input-group-append" role="button" style="margin-top: -1.75rem; margin-left: 18rem">
              <i class="fa fa-eye" *ngIf="passwordHidden" (click)="togglePasswordVisibility()"></i>
              <i class="fa fa-eye-slash" *ngIf="!passwordHidden" (click)="togglePasswordVisibility()"></i>
            </div>
          </span>
          <div class="error-form" *ngIf="
              loginForm.get('password').hasError('required') &&
              loginForm.get('password').touched
            ">
            Password is required.
          </div>
        </div>

        <br />

        <div style="color: gray; font-weight: 500; font-size: 10px">
          By continuing. You agree to Fluiditi
          <span style="color: #0f2c92; font-weight: 600">Terms of Use</span>&nbsp; and&nbsp;
          <span style="color: #0f2c92; font-weight: 600">Privacy Policy</span>
        </div>
        <br />

        <button type="submit" style="
            background: #0f2c92;
            border-radius: 9px;
            width: 62%;
            margin-left: 3rem;
            padding: 0.7rem 0.5rem;
          " class="btn btn-primary auth-form-btn" (click)="Login()" [disabled]="!loginForm.valid">
          Login
        </button>
      </form>
      <br />
      <br />
      <div style="color: #0f2c92; font-weight: 700; font-size: 12px" class="d-flex justify-content-between">
        <span (click)="showRegisterModal()" style="cursor: pointer">Create an account</span>

        <span (click)="forget()" style="cursor: pointer">Forgot Password ?</span>
      </div>
    </div>
  </div>
</ng-template>