import { Type } from "class-transformer";
import {
    AbstractIdResponse,
    AbstractResponse,
} from "./abstract-response.model";
import { Address, AddressCreate, AddressUpdate } from "./address.model";
import {
    Attachment,
    AttachmentCreate,
    AttachmentUpdate,
} from "./attachment.model";
import {
    Company,
    CompanyBasicInfo,
    CompanyPartialSummary,
    CompanySummary,
} from "./company.model";
import {
    ContactDetail,
    ContactDetailCreate,
    ContactDetailUpdate,
} from "./contact-detail.model";

// import { TaxGroup } from './tax-group.model';
// import { Tax } from './tax.model';
// import { Unit } from './unit.model';
import { Moment } from "moment";

export class Store extends AbstractResponse {
  name: string;
  templateName: string;
  prefixCode: string;
  @Type(() => ContactDetail)
  contactDetail: ContactDetail;
  sucessFlag: boolean;
  pointValue: number;
  currencyValue: number;
  moneyValue: number;
  taxName: string;
  licenseId: string;
  expiryDuration: Moment;
  tempStoreId: string;
  active: boolean;
  isUpdatable: boolean;
  @Type(() => Address)
  address: Address;
  taxNumber: string;
  expireDate: Moment;

  panNumber: string;
  alertSound: boolean;
  @Type(() => Company)
  company: Company;
  upiId: string;
  upiLink: string;
  zoneId: string;
  validTill: string;

  // @Type(() => Unit)
  // units: Unit[];

  @Type(() => Attachment)
  upiAttachment: Attachment;
  userLimit: number;
  itemLimit: number;
  purchaseTermsAndCondition: string;
  salesTermsAndCondition: string;
  posTermsAndCondition: string;
  currencyPlacement: string;

  prefixCodeExpenseCategory: string;
  prefixCodeExpense: string;
  prefixCodeCategory: string;
  prefixCodeItem: string;
  prefixCodeBrand: string;
  prefixCodeSupplier: string;
  prefixCodeCustomer: string;
  prefixCodePurchase: string;
  prefixCodePurchaseReturn: string;
  prefixCodeSale: string;
  prefixCodeSaleReturn: string;
  storeName: any;
  companyId: string;
  store: any;
  id: string;

  isSmsEnabled?(): boolean {
    return this.company.isSmsEnabled;
  }

  getCompanyLogoAttachment?(): Attachment {
    return this.company.logoAttachment;
  }
}

export class StoreData extends AbstractResponse {
  name: string;

  validTill: string;

  storeName: any;
  companyId: string;
  store: any;
  id: string;
}

export class StoreSummary extends AbstractIdResponse {
  name: string;
  @Type(() => ContactDetail)
  contactDetail: ContactDetail;
  pointValue: number;
  @Type(() => Address)
  address: Address;
  taxNumber: string;
  templateName: string;
  taxName: String;
  purchaseTermsAndCondition: string;
  panNumber: string;
  alertSound: boolean;
  @Type(() => CompanySummary)
  company: CompanySummary;

  upiId: string;

  currencyPlacement: string;

  isSmsEnabled?(): boolean {
    return this.company.isSmsEnabled;
  }

  getCompanyLogoAttachment?(): Attachment {
    return this.company.logoAttachment;
  }
}

export class storeData extends AbstractIdResponse {
  name: string;
  validTill: string;
  id: null;
}

export class StorePartialSummary extends AbstractIdResponse {
  name: string;

  alertSound: boolean;

  @Type(() => CompanyPartialSummary)
  company: CompanyPartialSummary;
  currencyPlacement: string;

  isSmsEnabled?(): boolean {
    return this.company.isSmsEnabled;
  }

  getCompanyLogoAttachment?(): Attachment {
    return this.company.logoAttachment;
  }
}

export class StoreListing extends AbstractIdResponse {
  name: string;
  code: string;
  prefixCode: string;
  companyId: string;
  companyName: string;
  userLimit: number;
  availableUser: number;
  itemLimit: number;
  availableItem: number;
  active: boolean;
  @Type(() => Address)
  address: Address;
}

export class StoreBasicInfo extends AbstractIdResponse {
  name: string;
  currencySymbol: string;
  currencyDecimalPlaces: number;
  @Type(() => CompanyBasicInfo)
  company: CompanyBasicInfo;
  pointValue: number;
  currencyValue: number;
  salesTermsAndCondition: string;
}

export class PaymentBasicInfo extends AbstractIdResponse {
  name: string;
}

export class StoreCreate {
  name: string;
  prefixCode: string;
  active: boolean;
  isUpdatable: boolean;
  contactDetail: ContactDetailCreate;

  address: AddressCreate;
  taxNumber: string;
  validTill: string;
  taxIds: string[];
  taxGroupIds: string[];
  licenseId: string;
  taxName: string;
  tempStoreId: string;
  pointValue: number;
  currencyValue: number;
  moneyValue: number;
  expiryDuration: string;
  panNumber: string;
  alertSound: boolean;
  companyId: string;
  upiId: string;
  upiLink: string;
  currencyId: string;
  unitIds: string[];
  paymentMethodIds: string[];
  upiAttachment: AttachmentCreate;
  userLimit: number;
  itemLimit: number;
  purchaseTermsAndCondition: string;
  salesTermsAndCondition: string;
  posTermsAndCondition: string;
  currencyPlacement: string;

  prefixCodeExpenseCategory: string;
  prefixCodeExpense: string;
  prefixCodeCategory: string;
  prefixCodeItem: string;
  prefixCodeBrand: string;
  prefixCodeSupplier: string;
  prefixCodeCustomer: string;
  prefixCodePurchase: string;
  prefixCodePurchaseReturn: string;
  prefixCodeSale: string;
  prefixCodeSaleReturn: string;
  templateName: string;
}

export class StoreUpdate {
  id: string;
  name: string;
  contactDetail: ContactDetailUpdate;

  moneyValue: number;
  address: AddressUpdate;
  taxNumber: string;
  taxIds: string[];
  taxGroupIds: string[];
  vatNumber: string;
  pointValue: number;
  currencyValue: number;
  expiryDuration: string;
  panNumber: string;
  alertSound: boolean;
  upiId: string;
  upiLink: string;
  currencyId: string;
  unitIds: string[];
  paymentMethodIds: string;
  upiAttachment: AttachmentUpdate;
  userLimit: number;
  itemLimit: number;
  purchaseTermsAndCondition: string;
  salesTermsAndCondition: string;
  posTermsAndCondition: string;
  validTill: string;
  licenseId: string;
  taxName: string;
  currencyPlacement: string;
  active: boolean;
  isUpdatable: boolean;
  templateName: string;
}

export const STORE_COLUMNS = {
  NAME: "Name",
  CODE: "Code",
  prefixCode: "prefixCode",
  ADDRESS: "Address",
  USER_LIMIT: "User Limit",
  USER_AVAILABLE: "Available User",
  ITEM_LIMIT: "Item Limit",
  ITEM_AVAILABLE: "Available Item",
  Active: "Active",
  COMPANY: "Company",
  ACTIONS: "Actions",
};

export class StoreDatatable {
  id: string;
  code: string;
  name: string;
  address: string;
  userLimit: number;
  availableUser: number;
  itemLimit: number;
  availableItem: number;
  active: boolean;
  company: string;
}

// export class StoreDetails extends AbstractResponse {

//     id: string;
//     name: string;
//     data: string;

//     address: {
//         address: string;
//         city: string;
//         createdBy: string;
//         createdDateTime: string;
//         id: string;
//         postCode: string
//     };
//     contactDetail: {
//         contactNumber: string;
//         email: string
//     };

export class StoreDetails {
  id: string;
  name: string;
  @Type(() => Address)
  address: Address;
  expiryDuration: string;
  @Type(() => Company)
  company: Company;
  @Type(() => ContactDetail)
  contactDetail: ContactDetail;
}
