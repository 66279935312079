import { AbstractResponse } from "./abstract-response.model";

export class Register extends AbstractResponse {
  firstName: string;
  lastName: string;
  email: string;
  phonenumber: string;
  password: string;
  gender: string;
}

export class RegisterCreate {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  password: string;
  gender: string;
  storeId: string;
}
