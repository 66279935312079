import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, HostListener, ViewChild, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, debounceTime } from 'rxjs';
import { LogoutCreate } from 'src/app/core/models/logout.model';
import { AlertService } from 'src/app/core/service/alert.service';
import { LocationService } from 'src/app/core/service/locationstore.service';
import { LoginService } from 'src/app/core/service/login.service';
import { LogoutService } from 'src/app/core/service/logout.service';
import { MycartService } from 'src/app/core/service/my-cart.service';
import { MywishlistService } from 'src/app/core/service/mywishlist.service';
import { StoreService } from 'src/app/core/service/store.service';
import { itemCommonSearchFiltersss, itemSearchFilter, locationFilter, searchFilter, searchItemFilter, sortBy } from 'src/app/core/utils/matrix-filter.util';
import { LoginModalComponent } from 'src/app/login-modal/login-modal.component';
import { environment } from 'src/environments/environment';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { getUnPagedAndActiveRequest } from 'src/app/core/utils/common.utils';
import { ItemService } from 'src/app/core/service/item.service';
import { GlobalFilter } from 'src/app/core/models/global-filter.model';
import { yangoosStorageKeys } from 'src/app/core/constant/app.constant';
import { ItemCategoryService } from 'src/app/core/service/item-category.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {



  productList: any;
  isCategoryDropdownVisible = false;


  categoryList: any;
  @Output() productListt = new EventEmitter<any>();

  title = 'appBootstrap';

  closeResult: string = '';
  keyword = 'name';

  currentCity!: string;
  currentLatitude!: number;
  currentLongitude!: number;
  searchInput!: string;

  isLoggedIn: boolean = false;

  loadDataSubscription: Subscription;
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild('loginmodalRef', { static: true }) loginmodalRef: LoginModalComponent;
  @ViewChild('auto') searchItemViewChild: AutocompleteComponent;
  @Output() onSelected = new EventEmitter<any>();

  public stick: boolean = false;
  selectedlogin: Headers = undefined;
  postCode: string;
  storeId: string;
  city: string;
  Location: Location[];
  enviroment: any;
  searchItem: any;
  categoryId: any;
  isBrandSelected = false;
  isCategorySelected = false;
  isAllProduct = true;
  loadingIndicator = false;
  totalItems: number;
  isProduct = false;
  isBrand = false;
  isCategory = false;
  @Input() myCartLengthUpdate: number;
  itemsPerPage = 10;
  currentPage = 0;
  myWhistListLength: number;
  storeDetails: any;
  userId: any;
  myWishListLength: number;
  constructor(
    private itemCategory: ItemCategoryService,
    private modalService: NgbModal,
    private locationService: LocationService,
    private logoutService: LogoutService,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    private http: HttpClient,
    private storeService: StoreService,
    private SpinnerService: NgxSpinnerService,
    private mycartService: MycartService,
    private myWishListService: MywishlistService,
    private loginService: LoginService,
    private itemService: ItemService,
    private router: Router

  ) {
    this.storeId = localStorage.getItem('storeId');
    this.enviroment = environment.yangoosApiUrl;
  }

  storeNumber: any;
  isDomainPresent: boolean = false;
  ngOnInit(): void {

    this.rreload();

    this.mycartService.receiveMyCartRefresh().subscribe({
      next: (data) => {
        if (data === true) {
          this.mycartService.getAll(this.userId, true).subscribe({
            next: (res) => {
              localStorage.setItem('cartItems', JSON.stringify(res.body.responseObject))
              this.myCartLengthUpdate = res.body.responseObject.length;
            }
          })
        }
      }
    })


    this.mycartService.getcartLength().subscribe(data => {
      this.myCartLengthUpdate = data;
    })

    this.myWishListService.getMyWishListLength().subscribe(data => {
      this.myWhistListLength = data;
    })

    this.loginService.loginStatus.subscribe((res) => {
      if (res) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    })
    this.userId = localStorage.getItem('userId');
    if (JSON.parse(localStorage.getItem("storeDetails"))) {
      this.storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
    }
    if (localStorage.getItem('userId')) {
      this.isLoggedIn = true;
      this.getCartLength();
      this.getMyWishListLength();
    }
    this.reload();
  }

  reload() {

    // if (this.storeId) {

    setTimeout(() => {
      this.storeId = localStorage.getItem('storeId');
      this.loadStoreDetails(this.storeId);
    }, 500);
    // }
  }

  categoryDropdown() {
    this.isCategoryDropdownVisible = !this.isCategoryDropdownVisible;
  }

  rreload() {
    if (this.storeId) {
      // this.loadData(this.storeId, this.currentPage, searchItemFilter(this.getRequestParam(this.currentPage)));
      this.loadCategory(this.storeId, this.currentPage);
      // this.loadBrand(this.storeId, this.currentPage);
    }
    if (localStorage.getItem('userId')) {
      this.userId = localStorage.getItem('userId');
    }
  }

  whatsappChat() {
    const storeDetail = JSON.parse(localStorage.getItem('storeDetails'))
    this.storeNumber = storeDetail.contactDetail.contactNumber;
    window.location.href = `https://wa.me/91${this.storeNumber}`
  }


  updateLoginStatus() {

    this.isLoggedIn = true;
    this.cdRef.detectChanges();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {


    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  requestotp(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  createuser(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  handleClick() {
    
  }

  showAddModal() {
    this.loginmodalRef.showAdd();
  }

  logout() {

    let logout = new LogoutCreate();

    this.logoutService.logouts(logout, false).subscribe({
      next: (res) => {

        this.showSuccessMessage(res.responseMessage)
        localStorage.removeItem('userId');
        localStorage.removeItem(yangoosStorageKeys.authenticationToken);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('cartItems')
        localStorage.removeItem('wishItems')
        localStorage.removeItem('recentOrderItems')

        this.loginService.loginStatus.next(false);
        this.isLoggedIn = false;
        // window.location.reload()
      },
      error: (res) => {
        this.alertService.errorAlert(res.responseMessage);
      }
    });

  }

  private showSuccessMessage(message) {
    this.alertService.successAlert(message);
  }

  getCartLength() {
    const userId = localStorage.getItem("userId");
    this.mycartService.getAll(userId, true).subscribe({
      next: (res) => {
        this.myCartLengthUpdate = res.body.responseObject.length;
        localStorage.setItem('cartItems', JSON.stringify(res.body.responseObject));
      }
    });
  }

  openWhatsapp() {
    window.open(`https://wa.me/91${this.storeDetails.contactDetail.contactNumber}`, "_blank")
  }

  getMyWishListLength() {
    const userId = localStorage.getItem("userId");
    this.myWishListService.getAll(userId, true).subscribe({
      next: (res) => {
        this.myWishListLength = res.body.responseObject.length;
        localStorage.setItem('wishItems', JSON.stringify(res.body.responseObject));
        this.myWishListService.sendMyWishListLength(this.myWishListLength);

      }
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadStoreDetails(storeId) {
    this.storeService.getStoreDetails(storeId).subscribe((res) => {
      this.storeDetails = res.responseObject
      localStorage.setItem("storeDetails", JSON.stringify(res.responseObject));
      JSON.parse(localStorage.getItem("storeDetails"));
    })
  }



  selectEvent(item) {
    this.router.navigateByUrl(`/home/productoverview/${item.id}`);
    this.searchItemViewChild.clear()
  }



  onChangeSearch(val: string) {
    const globalFilter = new GlobalFilter();
    globalFilter.storeId = this.storeId;
    const req = this.searchRequestparam(val);
    this.loadDataSearchItem(itemSearchFilter(req));
  }

  searchRequestparam(item) {
    return {
      query: item,
      storeId: this.storeId
    }
  }

  loadDataSearchItem(searchMatrixParams = undefined) {
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams
    }
    this.itemService.searchItemByQuery(matrixParams).subscribe({
      next: (res) => {
        this.searchItem = res.responseObject;
      }
    })

  }

  getRequestParam(currentPage): any {
    return {
      page: currentPage,
      size: this.itemsPerPage,
      sort: sortBy(),

    };
  }

  onFocused(e) {
  }

  loadDataSearch(searchMatrixParams = undefined) {
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams

    }


    this.itemService.searchItem(matrixParams, true).subscribe({
      next: (res) => {

      }

    })
  }


  selectCategory(categoryId) {
    this.isBrandSelected = false;
    this.isCategorySelected = true;
    this.isAllProduct = false;
    this.categoryId = categoryId;
    this.itemCategory.sendCategoryId(this.categoryId);
    this.router.navigateByUrl('/home/fashion');

    // this.loadDataByCategory(categoryId, this.currentPage, searchItemFilter(this.getRequestParam(this.currentPage)))

  }


  loadDataByCategory(categoryId, currentPage: number = 1, searchMatrixParams = undefined) {
    this.loadingIndicator = true;
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams;
    } else {
      matrixParams = this.getRequestParam(currentPage);
    }
    this.itemCategory.getAllByCategoryId(categoryId, matrixParams, true).subscribe({
      next: (res) => {
        this.productList = res.body.responseObject.content;
        this.totalItems = res.body.responseObject.totalElements;
        this.isProduct = false;
        this.isBrand = false;
        this.isCategory = true;
        this.productListt.emit(this.productList);

      },
      complete: () => this.stopLoadingIndicator(),
      error: (err) => this.subscribeError(err)
    });

  }

  private stopLoadingIndicator() {
    this.loadingIndicator = false;
    this.SpinnerService.hide();
  }

  private subscribeError(err) {
    this.stopLoadingIndicator();
  }


  loadCategory(storeId, currentPage: number = 1, searchMatrixParams = undefined) {
    this.SpinnerService.show();
    this.loadingIndicator = true;
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams;
    } else {
      matrixParams = this.getRequestParam(currentPage);
    }
    this.itemCategory.getAll(storeId, matrixParams, true).subscribe({
      next: (res) => {
        this.categoryList = res.body.responseObject;
        this.categoryList.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        this.SpinnerService.hide();
      },
      complete: () => this.stopLoadingIndicator(),
      error: (err) => this.subscribeError(err)
    });
  }

  


}


