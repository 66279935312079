<!-- <ng-template #template let-modal> -->
<!-- <ngx-spinner></ngx-spinner> -->
<!-- <br>
<br>
<br>
<br> -->
<app-login-modal #loginmodalRef></app-login-modal>
<div class="form my-4 mx-5"></div>
<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col-md-5  mx-auto ">
      <img src="../../../../assets/images/register.png" style="
          width: 94%;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        " alt="login" class="login-card-img" />
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="col-md-6  mx-auto" style="margin-top: auto; margin-bottom: auto; padding: 25px">
      <div class="row">
        <div class="col-md-12 text-center">
          <img [src]="logo" style="width: 10rem" alt="logo" class="logo" />
        </div>
      </div>

      <br />
      <br />

      <form class="validate-form" [formGroup]="registerForm">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <label for="">First Name</label>
                <input type="text" class="form-control" formControlName="firstName" tabindex="1"
                  placeholder="First Name" [maxlength]="100" [pattern]="" autofocus/>
                <!-- <div *ngIf="
                    f.firstName.invalid &&
                    (f.firstName.dirty || f.firstName.touched)
                  " class="text-danger" style="font-size: 10px">
                  First Name is required.
                </div> -->
<!-- change by mahesh Ec-60 -->
                <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)">
                  <div *ngIf="f.firstName.errors?.required"  class="text-danger" style="font-size: 10px">First name is required.</div>
                  <div *ngIf="f.firstName.errors?.maxLength"  class="text-danger" style="font-size: 10px">First name must be less than or equal to 100 characters.</div>
                  <div *ngIf="f.firstName.errors?.pattern"  class="text-danger" style="font-size: 10px">First name should contain only alphabetical characters.</div>
                </div>
<!-- end -->
                <div *ngIf="
                    !(
                      f.firstName.invalid &&
                      (f.firstName.dirty || f.firstName.touched)
                    )
                  " class="text-danger" style="font-size: 10px">
                  &nbsp;
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" tabindex="2"
                  placeholder="Last Name" [maxlength]="100" />
                <!-- <div *ngIf="
                    f.lastName.invalid &&
                    (f.lastName.dirty || f.lastName.touched)
                  " class="text-danger" style="font-size: 10px">
                  Last Name is required.
                </div> -->
                <!-- change by mahesh Ec-60 -->
                <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)">
                  <div *ngIf="f.lastName.errors?.required"  class="text-danger" style="font-size: 10px">Last name is required.</div>
                  <div *ngIf="f.lastName.errors?.maxLength"  class="text-danger" style="font-size: 10px">Last name must be less than or equal to 100 characters.</div>
                  <div *ngIf="f.lastName.errors?.pattern"  class="text-danger" style="font-size: 10px">Last name should contain only alphabetical characters.</div>
                </div>
                <!-- end -->
                <div *ngIf="
                    !(
                      f.lastName.invalid &&
                      (f.lastName.dirty || f.lastName.touched)
                    )
                  " class="text-danger" style="font-size: 10px">
                  &nbsp;
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="">Contact Number</label>
                <input type="text" class="form-control" [maxlength]="10" formControlName="contactNumber" tabindex="3"
                  placeholder="Contact No" />
                <div *ngIf="
                    f.contactNumber.invalid &&
                    (f.contactNumber.dirty || f.contactNumber.touched)
                  " class="text-danger" style="font-size: 10px">
                  Contact No is required.
                </div>
                <div *ngIf="
                    !(
                      f.contactNumber.invalid &&
                      (f.contactNumber.dirty || f.contactNumber.touched)
                    )
                  " class="text-danger" style="font-size: 10px">
                  &nbsp;
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Password</label>
                <div>
                  <div class="d-flex position-relative">
                    <input id="passwordInput" type="password" class="form-control" formControlName="password"
                      tabindex="4" placeholder="Password" [maxlength]="20" />
                    <span class="position-absolute" style="right: 0;">
                      <div class="input-group-append" style="
                          margin-top: .7rem;  
                          z-index: 12;">
                        <i class="fa fa-eye" *ngIf="passwordHidden" (click)="togglePasswordVisibility()"></i>
                        <i class="fa fa-eye-slash" *ngIf="!passwordHidden" (click)="togglePasswordVisibility()"></i>
                      </div>
                    </span>
                  </div>

                  
                  <!-- <div *ngIf="
                      f.password.invalid &&
                      (f.password.dirty || f.password.touched)
                    " class="text-danger" style="font-size: 10px">
                    Password is required.
                  </div> -->
                  <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                    <div *ngIf="f.password.errors?.required"  class="text-danger" style="font-size: 10px">password is required.</div>
                    <div *ngIf="f.password.errors?.maxLength"  class="text-danger" style="font-size: 10px">password must be less than or equal to 20 characters.</div>
                    <div *ngIf="f.password.errors?.minlength"  class="text-danger" style="font-size: 10px">password must be more than 8 characters.</div>
                  </div>
                  <div *ngIf="
                      !(
                        f.password.invalid &&
                        (f.password.dirty || f.password.touched)
                      )
                    " class="text-danger" style="font-size: 10px">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="">Email Id</label>
                <input type="email" class="form-control" formControlName="email" tabindex="5" placeholder="Email Id" [maxlength]="100" />
                <!-- <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="text-danger"
                  style="font-size: 10px">
                  Enter a valid email address.
                </div> -->    
                <!-- chnage by mahesh EC-60-->
                <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                  <div *ngIf="f.email.errors?.required"  class="text-danger" style="font-size: 10px">Email is required.</div>
                  <div *ngIf="f.email.errors?.maxLength"  class="text-danger" style="font-size: 10px">Email must be less than or equal to 100 characters.</div>
                  <div *ngIf="f.email.errors?.pattern"  class="text-danger" style="font-size: 10px">Enter a valid email address.</div>
                </div>
                <!-- end -->

                <div *ngIf="
                    !(f.email.invalid && (f.email.dirty || f.email.touched))
                  " class="text-danger" style="font-size: 10px">
                  &nbsp;
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Select Gender</label>
                <div class="form-group">
                  <ng-select [searchable]="false" formControlName="gender" placeholder="select gender" tabindex="6"
                    [clearable]="false">
                    <ng-option [value]="'male'" label="Male">Male</ng-option>
                    <ng-option [value]="'female'" label="female">Female</ng-option>
                    <ng-option [value]="'others'" label="Others">Others</ng-option>
                  </ng-select>
                </div>

                <div *ngIf="
                    f.gender.invalid && (f.gender.dirty || f.gender.touched)
                  " class="text-danger" style="font-size: 10px">
                  Gender is required.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <br /> -->
        <!-- <div style="color: gray; font-weight: 800; font-size: 10px;">
          <input type="checkbox" id="agreeCheckbox" />&nbsp;&nbsp;
          <label for="agreeCheckbox">
            By continuing, you agree to Fluiditi <span style="color: #0F2C92;">Terms of Use</span> and
            <span style="color: #0F2C92;">Privacy Policy</span>
          </label>
        </div> -->

        <!-- <br /> -->
        <button type="submit" style="color: white; border-radius: 9px; width: 100%"
          class="btn btn-warning auth-form-btn mt-4 py-2" (click)="registerCreate()" [disabled]="!registerForm.valid">
          CREATE
        </button>
      </form>

      <div class="row mt-3 p-0">
        <div class="text-center p-0">
          <button routerLink="/home/fashion" class="btn btn-solid">
            Back to Home Page
          </button>
        </div>
      </div>

      <!-- <button type="submit" style="color: #0F2C92; border-radius: 9px; width: 100%;"
        class="btn btn-light auth-form-btn shadow-sm bg-white rounded">Existing User? Log in</button> -->
    </div>
  </div>
</div>

<!-- </ng-template> -->