
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
// import { NgxPrintModule } from 'ngx-print';

import { NumericDirective } from '../directives/only-number.directive';

import {HasAnyAuthorityDirective} from '../directives/has-any-authority.directive';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { FileUploadModule, } from '@iplab/ngx-file-upload';

@NgModule({
  declarations: [HasAnyAuthorityDirective,NumericDirective],
  imports:[
    // NgxPrintModule
  ],
  exports: [HasAnyAuthorityDirective,
    NumericDirective, 
    // NgxPrintModule, 
    TranslateModule,FileUploadModule,
    NgZorroAntdModule]
})
export class YangoosSharedModule { }
