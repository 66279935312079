import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { environment } from './../../../environments/environment';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { GenericHttpResponse } from '../../core/models/generic-http-response.model';
import { Page } from '../../core/models/page.model';
import { Item } from '../models/item.model';
import { Mywishlist, MywishlistCreate, Mywishlistget } from '../models/mywishlist.model';
import { AuthService } from './auth.service';
import { yangoosStorageKeys } from '../constant/app.constant';
@Injectable({
  providedIn: 'root'
})
export class MywishlistService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + '/api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService,private injector:Injector) {
    super(httpClient, loadingBarService);
  }


  findById(id: string, noLoadingMask = false): Observable<GenericHttpResponse<Item>> {
    
    return this.httpGet({
      url: `${this.baseUrl}/item/${id}`,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Item>(Item),
    }) as Observable<GenericHttpResponse<Item>>;

  }

  save(create: MywishlistCreate, noLoadingMask = false): Observable<GenericHttpResponse<Mywishlist>> {
    
    return this.httpPost({
      url: `${this.baseUrl}/wishlist/create`,
      payload: create,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Mywishlist>(Mywishlist),
    }) as Observable<GenericHttpResponse<Mywishlist>>;

  }



  getAll(id = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Mywishlistget[]>>> {

    let url = `${this.baseUrl}/wishlist/user/${id}`;
    return this.httpGet({
      url: url,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Mywishlistget>>(new Page<Mywishlistget>(Mywishlistget)),
    }) as Observable<GenericHttpResponse<Page<Mywishlistget[]>>>;

  }

  delete(ids: string, noLoadingMask = false): Observable<GenericHttpResponse<string>> {
    let params = new HttpParams();
    // for (const id of ids) {
    //   params = params.append('ids', id);
    // }
    
    return this.httpDelete({
      url: `${this.baseUrl}/wishlist/${ids}`,
      params: params,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<String>(String),
    }) as Observable<GenericHttpResponse<string>>;

  }

  //   getAlls(id = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Itemview[]>>> {
  //     const metrixParams = createMatrixOption(params);
  //     const options = createRequestOption(params);

  //     let url = `${this.baseUrl}/item/${id}`;
  //     return this.httpGet({
  //       url: url,
  //       params: options,
  //       noLoadingMask: noLoadingMask,
  //       returnType: new GenericHttpResponse<Page<Itemview>>(new Page<Itemview>(Itemview)),
  //     }) as Observable<GenericHttpResponse<Page<Itemview[]>>>;

  //   }

  private myWhistListLength = new Subject<any>();

  sendMyWishListLength(length: any) {
    this.myWhistListLength.next(length);
  }

  getMyWishListLength() {
    return this.myWhistListLength.asObservable();
  }

  refreshToken(): Observable<any> {

    const authService=this.injector.get(AuthService);
    const refreshToken = localStorage.getItem('refreshToken');
    const body = {
      refreshToken: refreshToken
    }


   return authService.refreshToken(body, false).pipe(
    catchError((error)=>{

      localStorage.setItem(yangoosStorageKeys.authenticationToken, error.accessToken);
        localStorage.setItem('refreshToken', error.refreshToken);
      return throwError(error);
    })
      
        
      
    );
  }
  // refreshToken() {

  //   const authService=this.injector.get(AuthService);
  //   const refreshToken = localStorage.getItem('refreshToken');
  //   const body = {
  //     refreshToken: refreshToken
  //   }


  //  return authService.refreshToken(body, false).subscribe({
  //     next: (res: any) => {
  //       localStorage.setItem(yangoosStorageKeys.authenticationToken, res.accessToken);
  //       localStorage.setItem('refreshToken', res.refreshToken);
  //     }
  //   })
  // }


}