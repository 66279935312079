import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    
  readonly baseUrl: string = environment.yangoosApiUrl + '/api';
 
  constructor(private http:HttpClient) { }
  url =`${this.baseUrl}/subscription/create`
  saveSubscriber(data:any){
    return this.http.post(this.url,data);
  }
}