import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class MycartDataService {

    constructor(private httpClient: HttpClient) { }

    baseUrl = environment.yangoosApiUrl + "/api"

    private cartDetails = new BehaviorSubject<any>({

        displayCartDetails: '',
        cartPayloadDetails: ''
    });


    setCartDetails(display) {
        this.cartDetails.next(display)
    }


    getCartDetails() {
        return this.cartDetails.asObservable();
    }


    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');

        const body = {
            refreshToken: refreshToken
        }

        const url = ` ${this.baseUrl}/auth/RefreshToken`;

        return this.httpClient.post(url, body)
    }

    logout(){
        const url= `${this.baseUrl}/auth/signout`;
        return this.httpClient.post(url,'');
    }

}