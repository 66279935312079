import { Type } from "class-transformer";
import {
    AbstractIdResponse,
    AbstractResponse,
} from "./abstract-response.model";
import { Permission } from "./permission.model";

export class Role extends AbstractResponse {
  name: string;
  displayName: string;
  isUpdatable: boolean;
  @Type(() => Permission)
  permissions: Permission[];
}

export class RoleBasicInfo extends AbstractIdResponse {
  name: string;
  displayName: string;
}

export class RoleListing extends AbstractIdResponse {
  name: string;
  displayName: string;
  isUpdatable: boolean;
}

export class RoleCreate {
  displayName: string;
  permissionIds: string[];
}

export class Roleupdate {
  id: string;
  permissionIds: string[];
}

export const ROLE_COLUMNS = {
  NAME: "Name",
  ACTIONS: "Actions",
};

export class RoleDatatable {
  id: string;
  name: string;
  isUpdatable: boolean;
}
