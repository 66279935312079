import { AbstractResponse } from "./abstract-response.model";

export class ContactDetail extends AbstractResponse {
  contactNumber: string;
  email: string;
}

export class ContactDetailCreate {
  contactNumber: string;
  email: string;
}

export class ContactDetailUpdate {
  id?: string;
  contactNumber: string;
  email: string;
}

export class StoreDetailCreate {
  storeName: string;
  storeDate: Date;
}

export class StoreDetailUpdate {
  id?: string;
  storeName: string;
  storeDate: string;
}
