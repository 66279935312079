import { Type } from "class-transformer";
import {
  AbstractIdResponse,
  AbstractResponse,
} from "./abstract-response.model";
import { Address, AddressCreate, AddressUpdate } from "./address.model";
import {
  Attachment,
  AttachmentCreate,
  AttachmentUpdate,
} from "./attachment.model";
import {
  ContactDetail,
  ContactDetailCreate,
  ContactDetailUpdate,
  StoreDetailCreate,
} from "./contact-detail.model";
import { Role } from "./role.model";
import { Store } from "./store.model";

export class Company extends AbstractResponse {
  prefixCode: string;
  name: string;
  @Type(() => ContactDetail)
  contactDetail: ContactDetail;
  @Type(() => Address)
  address: Address;
  @Type(() => Attachment)
  logoAttachment: Attachment;
  userLimit: number;
  availableUser: number;
  storeLimit: number;
  availableStore: number;
  itemLimit: number;
  negativeStockSale: string;
  weighingScale: string;
  availableItem: number;
  @Type(() => Role)
  companyRoles: Role[];
  storeList: Store[];
  isSmsEnabled: boolean;

  prefixCodePaymentMethod: string;
  prefixCodeCurrency: string;
  prefixCodeUnit: string;
  prefixCodeTax: string;
  prefixCodeTaxGroup: string;
  prefixCodeStore: string;
}

export class CompanyListing extends AbstractResponse {
  name: string;
  code: string;
  @Type(() => Address)
  address: Address;
  @Type(() => Attachment)
  logoAttachment: Attachment;
  userLimit: number;
  availableUser: number;
  storeLimit: number;
  availableStore: number;
  itemLimit: number;
  availableItem: number;
  isSmsEnabled: boolean;
}

export class storeData extends AbstractResponse {
  name: string;
  id: string;
}

export class License extends AbstractIdResponse {
  id: string;
  licenseType: string;
  invalidStoreCount: number;
  validStoreCount: number;
  companyId: string;
}

export class Status extends AbstractIdResponse {
  invalidStoreCount: number;
  validStoreCount: number;
  companyId: string;
}

export class CompanyBasicInfo extends AbstractIdResponse {
  name: string;
}

export class CompanySummary extends AbstractIdResponse {
  name: string;
  isSmsEnabled: boolean;
  @Type(() => Attachment)
  logoAttachment: Attachment;
}

export class CompanyPartialSummary extends AbstractIdResponse {
  name: string;
  isSmsEnabled: boolean;
  @Type(() => Attachment)
  logoAttachment: Attachment;
}

export class CompanyCreate {
  name: string;
  prefixCode: string;
  contactDetail: ContactDetailCreate;
  multipleStore: StoreDetailCreate;
  address: AddressCreate;
  logoAttachment: AttachmentCreate;
  userLimit: number;
  // storeList: any[];
  availableUser: number;
  storeLimit: number;
  availableStore: number;
  itemLimit: number;
  availableItem: number;
  isSmsEnabled: boolean;
  companyRoleIds: string[];

  prefixCodePaymentMethod: string;
  prefixCodeCurrency: string;
  prefixCodeUnit: string;
  prefixCodeTax: string;
  prefixCodeTaxGroup: string;
  negativeStockSale: string;
  weighingScale: string;
}

export class createStoreData {
  // id: string;
  storeList: any[];
}

export class updateStoreData {
  // id: string;
  storeList: any[];
}

export class CompanyUpdate {
  id: string;
  name: string;
  contactDetail: ContactDetailUpdate;
  address: AddressUpdate;
  logoAttachment?: AttachmentUpdate;
  userLimit: number;
  storeList: any[];
  weighingScale: string;
  availableUser: number;
  negativeStockSale: string;
  storeLimit: number;
  availableStore: number;
  itemLimit: number;
  availableItem: number;
  isSmsEnabled: boolean;
  companyRoleIds: string[];
}

export const COMPANY_COLUMNS = {
  NAME: "Name",
  CODE: "Code",
  ADDRESS: "Address",
  USER_LIMIT: "User Limit",
  USER_AVAILABLITY: "Available User",
  STORE_LIMIT: "Store Limit",
  STORE_AVAILABLITY: "Available Store",
  ITEM_LIMIT: "Item Limit",
  ITEM_AVAILABLITY: "Available Item",
  ACTIONS: "Actions",
};

export class CompanyDatatable {
  id: string;
  code: string;
  name: string;
  address: string;
  userLimit: number;
  availableUser: number;
  storeLimit: number;
  availableStore: number;
  itemLimit: number;
  availableItem: number;
  isSmsEnabled: boolean;
  logoAttachment: Attachment;
}
