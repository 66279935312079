import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Principal } from '../service/principal.service';
import { roleEntity, permissions } from '../constant/app.constant';
import { Subscription } from 'rxjs';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasAnyAuthority="'target,entity,permission'">...</some-element>
 *  Here,
 *        target is a screen or any global object,property
 *        entity may be anyone from  roleEntity = {
                                            "role":"ROLE",
                                            "user":"USER",
                                            "site":"SITE"
                                            }
 *        permission may be anyonefrom  permissions = {
                                        "update":"UPDATE",
                                        "delete":"DELETE",
                                        "create":"CREATE",
                                        "view":"VIEW"
                                        } 
         <some-element *hasAnyAuthority="'component,role,update'">...</some-element>
 * ```
 */
@Directive({
    selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective {

    authStatSubscription: Subscription;

    constructor(
        private principal: Principal,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef) { }


    @Input()
    set hasAnyAuthority(value: string) {
        let entity;
        let permission;
        let target;
        if (value) {
            if (value === 'IGNORE_PERMISSION') {
                // Render view default
                this.updateView(undefined, undefined, true);
            } else {
                const valueElemets = value.split(',');
                target = valueElemets[0];
                if (roleEntity && roleEntity[valueElemets[1]]) {
                    entity = valueElemets[1];
                }

                if (permissions && permissions[valueElemets[2]]) {
                    permission = valueElemets[2];
                }

                if (entity && permission) {
                    const rolePermission = ('ROLE_' + entity + '_' + permission).toUpperCase();
                    this.updateView(target, rolePermission);
                    this.authStatSubscription = this.principal.getAuthenticationState().subscribe(identity => this.updateView(target, rolePermission));
                } else {
                    console.warn('entity or permission missing in application constants. missing entity for ' + value);
                }
            }


        } else {
            this.viewContainerRef.clear();
        }

    }

    private updateView(target, value, isIgnorePermission = false): void {
        if (isIgnorePermission === true) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.principal.getPermissions().then(authData => {
                const val: any = target;
                if (val) {
                    if (authData) {
                        if (authData && authData.get(target)) {
                            if (!this.isPermissionContains(value, authData.get(target))) {
                                this.viewContainerRef.clear();
                            } else {
                                this.viewContainerRef.createEmbeddedView(this.templateRef);
                            }
                        }
                    } else {
                        this.viewContainerRef.clear();
                    }
                } else {
                    this.viewContainerRef.clear();
                }
            });
        }

    }

    private isPermissionContains(permission, authDataPermissions: String[]) {
        if (permission) {
            if (authDataPermissions) {
                if (authDataPermissions.indexOf(permission) > -1) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
}
