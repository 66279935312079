import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { searchDomain } from "../core/models/search-domain.model";
import { FindDominService } from "../core/service/find-domin.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
  domainData: searchDomain;

  constructor(private router: Router, private findByDomain: FindDominService) {
    const address = window.location.host;
    this.findByDomainAddress(address);
  }

  ngOnInit(): void { }

  findByDomainAddress(address) {
    this.findByDomain.searchByDomain(address).subscribe({
      next: (res) => {
        this.domainData = res.responseObject;
        environment.storeId = this.domainData.storeId;
        localStorage.setItem("storeId", this.domainData.storeId);
        this.router.navigateByUrl("/home/fashion");
      },
    });
  }
}
