// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",

  // yangoosApiUrl: "https://yangoospos.com:4200",
  // yangoosApiUrl: 'https://yangoospos.com:1100',

  yangoosApiUrl: `https://ecom.yangoostechnologies.com`,
  // yangoosApiUrl: `http://localhost:8092`,
  isUserLogIn: false,
  // storeId:'451aa63b-8fd8-4444-a6ac-7c1154aec11d', 
  // storeId:'956e238c-d1b1-4999-966e-b4bc98cc1ee6',
  storeId: "",
  pageEntriesOptions: [10, 25, 50, 100],

  dateFormatISO: "yyyy-MM-dd",
  dateFormatRange: "MMM dd,yyyy",
  dateRangeSelectorSize: "large",
  dateRangeSelectorAllowClear: false,
  dateTimeFormatISO: "yyyy-MM-DD[T]HH:mm:ss.SSS",
  dateTimeFormatView: "yyyy-MM-dd HH:mm",
  timeFormatView: "hh:mm a",
  dateFormatView: "yyyy/MM/dd",
  dateTimeFormatFileName: "yyyy-MM-DD-HH-mm-ss",
  acceptedImageExtensions: ["jpg", "jpeg", "png"],
  acceptedCsvExtensions: ["csv"],
  addBtnClass: "btn btn-primary w-100 font-16 m-b-15 btn-lg",
  editBtnClass: "btn btn-info w-100 font-16 m-b-15 btn-lg",
  cancelBtnClass: "btn btn-light w-100 font-16 m-b-15 btn-lg",
  fieldSetClass: "border border-info rounded-3 p-3 mt-3",
  fieldSetLegendClass:
    "col-form-label control-label float-none font-weight-600 text-dark w-auto",
  // firebase: {
  // apiKey: "AIzaSyAk0shMuVlGSd9H-mdEkBZnVUC8tgc366E",
  // authDomain: "fluidity-5b9b2.firebaseapp.com",
  // projectId: "fluidity-5b9b2",
  // storageBucket: "fluidity-5b9b2.appspot.com",
  // messagingSenderId: "517118668624",
  // appId: "1:517118668624:web:f32a0115a1071473770655",
  // measurementId: "G-9BS8GB5EN8",
  // },

  booleanSelectOptions: [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
