import { AbstractResponse } from "./abstract-response.model";

export class Attachment extends AbstractResponse {
  type: string;
  size: number;
  name: string;
  mimeType: string;
  data: string;
  url: string;
}

export class AttachmentCreate {
  size: number;
  name: string;
  mimeType: string;
  data: string;
}

export class AttachmentUpdate {
  id?: string;
  size: number;
  name: string;
  mimeType: string;
  data: string;
}
