import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AbstractRestService } from "./abstract-rest.service";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { GenericHttpResponse } from "../../core/models/generic-http-response.model";
import { StoreDetails } from "../models/store.model";
import { createRequestOption } from "../utils/http-request.util";

@Injectable({
  providedIn: "root",
})
export class StoreService extends AbstractRestService {
  readonly baseUrl: string = environment.yangoosApiUrl + "/api";

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }

  getStoreDetails(
    storeId: string,
    params = undefined,
    noLoadingMask = true
  ): Observable<GenericHttpResponse<StoreDetails>> {
    const options = createRequestOption(params);
    let url = `${this.baseUrl}/storeDetail/${storeId}`;

    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<StoreDetails>(
        StoreDetails > StoreDetails
      ),
    }) as Observable<GenericHttpResponse<StoreDetails>>;
  }
}
