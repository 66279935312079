import { AbstractResponse } from "./abstract-response.model";

export class Brand extends AbstractResponse {
  description: string;
  id: string;
  name: string;
}

export class BrandCreate {
  description: string;
  id: string;
  name: string;
}
