import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
// import { UserService } from './user.service';
import { Permission } from '../models/permission.model';
import { yangoosStorageKeys } from '../constant/app.constant';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(
         private localStorageService: LocalStorageService) { }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;

        this.userIdentity? this.authenticationState.next(this.userIdentity): '';
        // if(this.userIdentity){
        //     this.authenticationState.next(this.userIdentity);
        // }

    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }
        if (!authorities) {
            return true;
        }
        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    getPermissions(): Promise<Map<String, String[]>> {
        return Promise.resolve(this.userIdentity.authData);
    }


    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
       
    }

    permissionListToMap(permissions: Permission[]) {
        let keys = [];
        let map = new Map<String, Permission[]>();
        permissions.forEach((e) => {
            if (keys.indexOf(e.target) === -1) {
                keys.push(e.target);
            }
        })

        if (keys) {
            keys.forEach(element => {
                let data = [];
                permissions.forEach((e) => {
                    if (element === e.target) {
                        data.push(e.name);
                    }
                });
                map.set(element, data);
            });
            return map;
        }

    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}
