import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { environment } from './../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { GenericHttpResponse } from '../../core/models/generic-http-response.model';
import { Page } from '../../core/models/page.model';
import { Brand } from '../models/brand.model';
import { createMatrixOption, createRequestOption } from '../utils/http-request.util';


@Injectable({
  providedIn: 'root'
})
export class BrandService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + '/api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }


  getAll(id=undefined,params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Brand[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/${id}/brands`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Brand>>(new Page<Brand>(Brand)),
    }) as Observable<GenericHttpResponse<Page<Brand[]>>>;

  }

  getAllByBrandId(id: string,params = undefined, noLoadingMask = false): Observable<GenericHttpResponse<Brand[]>> {
   
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);
   
    return this.httpGet({
      url: `${this.baseUrl}/item/by-brand/${id}${metrixParams}`,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Brand>(Brand),
    }) as Observable<GenericHttpResponse<Brand[]>>;

  }

  brandId= new Subject<string>();

  sendBrandId(brandId){
    this.brandId.next(brandId);
  }

  getBrandId(){
  return  this.brandId.asObservable();
  }

}
