import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { GenericHttpResponse } from '../../core/models/generic-http-response.model';
import { Page } from '../../core/models/page.model';
import { createMatrixOption, createRequestOption } from '../utils/http-request.util';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + 'api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }


 
  getAll(params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Location[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/item/get-all/item-partial-summary${metrixParams}`;
    return this.httpGet({
      url: url,
      // params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Location>>(new Page<Location>(Location)),
    }) as Observable<GenericHttpResponse<Page<Location[]>>>;

  }

  


}
