import { Component, OnInit, Input } from '@angular/core';
import { StoreDetails } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  storeDetails: StoreDetails;
  storeId:string;
  constructor( private storeService: StoreService) { 
  }
  
  ngOnInit(): void {

    setTimeout(() => {
      this.storeId=localStorage.getItem('storeId');
      this.storeService.getStoreDetails(this.storeId).subscribe((res) => {
      this.storeDetails=res.responseObject;

    })    
  },500)
}
}