export class GlobalFilter {
  companyId?: String;
  storeId?: String;
  isRaw?: string;
}

export class GlobalFilters {
  companyId?: String;
  storeId?: String;
  isRaw?: string;
  id?: string;
}
