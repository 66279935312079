
export class Login {
  id: string;
  token: string;
  type: string;
  refreshToken: string;
  email: string;
  accessToken: string;
  tokenType: string;
  username: string;
}

export class LoginCreate {
  email: string;
  password: string;
  username: string;
  storeId: string;
}
