import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AbstractRestService } from "./abstract-rest.service";
import { environment } from "./../../../environments/environment";
import { Observable, Subject } from "rxjs";
import { GenericHttpResponse } from "../../core/models/generic-http-response.model";
import { Page } from "../../core/models/page.model";
import {
  ItemCategory,
  StandardCategory,
  TopCategory,
} from "../models/item-category.model";

import {
  createMatrixOption,
  createRequestOption,
} from "../utils/http-request.util";

@Injectable({
  providedIn: "root",
})
export class ItemCategoryService extends AbstractRestService {
  readonly baseUrl: string = environment.yangoosApiUrl + "/api";

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }

  findById(
    id: string,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<ItemCategory>> {
    return this.httpGet({
      url: `${this.baseUrl}/category/${id}`,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<ItemCategory>(ItemCategory),
    }) as Observable<GenericHttpResponse<ItemCategory>>;
  }
  getAllByCategoryId(
    id: string,
    params = undefined,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<ItemCategory>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    return this.httpGet({
      url: `${this.baseUrl}/item/by-category/${id}${metrixParams}`,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<ItemCategory>(ItemCategory),
    }) as Observable<GenericHttpResponse<ItemCategory>>;
  }

  getTopCategory() {
    return this.httpClient.get<any>(
      `https://13.233.95.228:8081/api/top-selling?storeId=${environment.storeId}`
    );
  }

  getTopCategorys(storeId): Observable<TopCategory[]> {
    return this.httpGet({
      url: `${this.baseUrl}/top-selling/${storeId}`,

      returnType: new TopCategory(),
    }) as Observable<TopCategory[]>;
  }

  getTopTenCategory(
    params = undefined,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<ItemCategory>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    return this.httpGet({
      url: `${this.baseUrl}/top-selling/${metrixParams}`,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<ItemCategory>(ItemCategory),
    }) as Observable<GenericHttpResponse<ItemCategory>>;
  }

  // save(create: ItemCategoryCreate, noLoadingMask = false): Observable<GenericHttpResponse<ItemCategory>> {
  //   return this.httpPost({
  //     url: `${this.baseUrl}/category`,
  //     payload: create,
  //     noLoadingMask: noLoadingMask,
  //     returnType: new GenericHttpResponse<ItemCategory>(ItemCategory),
  //   }) as Observable<GenericHttpResponse<ItemCategory>>;

  // }

  // delete(ids: string[], noLoadingMask = false): Observable<GenericHttpResponse<string>> {
  //   let params = new HttpParams();
  //   for (const id of ids) {
  //     params = params.append('ids', id);
  //   }
  //   return this.httpDelete({
  //     url: `${this.baseUrl}/category`,
  //     params: params,
  //     noLoadingMask: noLoadingMask,
  //     returnType: new GenericHttpResponse<String>(String),
  //   }) as Observable<GenericHttpResponse<string>>;

  // }

  // getAllListing(params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<ItemCategoryList[]>>> {
  //   const metrixParams = createMatrixOption(params);
  //   const options = createRequestOption(params);

  //   let url = `${this.baseUrl}/category/get-all/listing${metrixParams}`;
  //   return this.httpGet({
  //     url: url,
  //     params: options,
  //     noLoadingMask: noLoadingMask,
  //     returnType: new GenericHttpResponse<Page<ItemCategoryList>>(new Page<ItemCategoryList>(ItemCategoryList)),
  //   }) as Observable<GenericHttpResponse<Page<ItemCategoryList[]>>>;

  // }

  getAll(
    id = undefined,
    params = undefined,
    noLoadingMask = true
  ): Observable<GenericHttpResponse<Page<ItemCategory[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/category/${id}${metrixParams}`;
    // let url = `${this.baseUrl}/${id}/category/${metrixParams}`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<ItemCategory>>(
        new Page<ItemCategory>(ItemCategory)
      ),
    }) as Observable<GenericHttpResponse<Page<ItemCategory[]>>>;
  }

  getAllCategory(
    id = undefined,
    params = undefined,
    noLoadingMask = true
  ): Observable<GenericHttpResponse<Page<StandardCategory[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/standardCategory`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<StandardCategory>>(
        new Page<StandardCategory>(StandardCategory)
      ),
    }) as Observable<GenericHttpResponse<Page<StandardCategory[]>>>;
  }

  categoryId = new Subject<string>();

  sendCategoryId(categoryId) {
    this.categoryId.next(categoryId);
  }

  getCategoryId() {
    return this.categoryId.asObservable();
  }
}
