export const roleEntity = {
  user: "USER",
  role: "ROLE",
  tax: "TAX",
  currency: "CURRENCY",
  units: "UNITS",
  payment_method: "PAYMENT_METHOD",
  site_settings: "SITE_SETTINGS",
  company_profile: "COMPANY_PROFILE",
  dashboard: "DASHBOARD",
  places: "PLACES",
  visitor: "VISITOR",
  expenses: "EXPENSES",
  expenses_category: "EXPENSES_CATEGORY",
  items: "ITEMS",
  employee: "EMPLOYEE",
  bank_account: "BANK_ACCOUNT",
  amount_transfer: "AMOUNT_TRANSFER",
  items_import: "ITEMS",
  items_category: "ITEMS_CATEGORY",
  items_print: "ITEMS_PRINT",
  brand: "BRAND",
  bank_statement: "BANK_STATEMENT",
  suppliers: "SUPPLIERS",
  suppliers_payment: "SUPPLIERS_PAYMENT",
  customers: "CUSTOMERS",
  customers_payment: "CUSTOMERS_PAYMENT",
  purchase: "PURCHASE",
  purchase_payment: "PURCHASE_PAYMENT",
  purchase_return: "PURCHASE_RETURN",
  purchase_return_payment: "PURCHASE_RETURN_PAYMENT",
  sales_include_pos: "SALES_INCLUDE_POS",
  sales_include_pos_payment: "SALES_INCLUDE_POS_PAYMENT",
  sales_return: "SALES_RETURN",
  sales_return_payment: "SALES_RETURN_PAYMENT",
  sms: "SMS",
  sms_template: "SMS_TEMPLATE",
  sms_api: "SMS_API",
  report: "REPORT",
  store: "STORE",
  company: "COMPANY",
};

export const permissions = {
  add: "ADD",
  edit: "EDIT",
  delete: "DELETE",
  view: "VIEW",
  select: "SELECT",
  label: "LABELS",
  items: "ITEMS",
  import: "IMPORT",
  send: "SEND",
  sales: "SALES",
  sales_return: "SALES_RETURN",
  purchase: "PURCHASE",
  purchase_return: "PURCHASE_RETURN",
  expenses: "EXPENSES",
  profit: "PROFIT",
  stock: "STOCK",
  item_sales: "ITEM_SALES",
  item_purchase: "ITEM_PURCHASE",
  purchase_payment: "PURCHASE_PAYMENT",
  customers_payment: "CUSTOMERS_PAYMENT",
  suppliers_payment: "SUPPLIERS_PAYMENT",
  sales_payment: "SALES_PAYMENT",
  item_expired: "ITEM_EXPIRED",
};

export const yangoosStorageKeys = {
  company: "company",
  store: "store",
  user: "user",
  userLang: "userLang",
  authenticationToken: "authenticationtoken",
  rememberMe: "rememberMe",
  date: "date",
  dates: "dates",
  initialbalance: "initialbalance",
  rewardpoint: "rewardpoint",
  customer: "customer",
  companylist: "companylist",
  storelist: "storelist",
  sale: "sale",
  config: "config",
  Weight: "Weight",
  selectedPort: "selectedPort",
};
