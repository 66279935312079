import { AbstractResponse } from "./abstract-response.model";

export class Address extends AbstractResponse {
  address: string;
  addressType: string;
  city: string;
  country: string;
  doorNo: string;
  id: string;
  name: string;
  pinCode: string;
  state: string;
  userId: number;
}

export class AddressCreate {
  address: string;
  addressType: string;
  city: string;
  country: string;
  doorNo: string;
  id: string;
  name: string;
  pinCode: string;
  state: string;
  userId: string;

}

export class AddressUpdate {
  address: string;
  addressType: string;
  city: string;
  country: string;
  doorNo: string;
  id: string;
  name: string;
  pinCode: string;
  state: string;
  userId: string;
}

export class AddressList {
  address: string;
  addressType: string;
  city: string;
  country: string;
  doorNo: string;
  id: string;
  name: string;
  pinCode: string;
  state: string;
  userId: number;
}

export class deleteAddress {
  responseMessage: string;
  responseStatus: boolean;
  responseStatusCode: number;
  responseObject: string;
}
