<app-login-modal #loginmodalRef></app-login-modal>

<!-- start -->
<header [ngClass]="class" [class.fixed]="stick && sticky" class="p-2 w-100">
  <div class="mobile-fix-option ">

    <div class="row row-cols-1 text-center align-items-center text-white my-0" *ngIf="!isLoggedIn">

      <li class="compare mobile-wishlist text-left p-1  " (click)='showAddModal()'>
        <img src="../../../../assets/images/icon/avatar.png" style="width: 1.5rem;height: 1.5rem;margin-right: .5rem;filter: brightness(0) invert(1);">
        <span>Login
        </span>
      </li>
    </div>

    <div class="row row-cols-3 text-center align-items-center text-white my-0" *ngIf="isLoggedIn">

      <li class="compare mobile-wishlist text-left p-1  ">
        <a style="color: black;" [routerLink]="['/shop/wishlist']"><img style="width: 15px;"
            src="../../../../assets/images/Wishlist.svg"></a>{{myWhistListLength}}
      </li>

      <li class="mobile-wishlist  p-1  ">
        <a style="color: black;" [routerLink]="['/shop/cart']"><img style="width: 15px;"
            src="../../../../assets/images/Cart.svg"></a>
        {{myCartLengthUpdate}}
      </li>

      <li class="nav-item dropdown mobile-wishlist  p-1  ">
        <a style="color: black;" class="dropdown-toggle text-white" href="#" role="button" id="dropdownMenuLink"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img style="width: 15px;"  src="../../../../assets/images/user.svg" alt="User Icon">

        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">

          <a class="dropdown-item" [routerLink]="['/home/myprofile']">My Profile</a>
          <a class="dropdown-item" [routerLink]="['/home/myorder']">My Orders</a>
          <!-- <a class="dropdown-item" >Notification</a> -->
          <a class="dropdown-item">24x7 Customer care</a>
          <a class="dropdown-item" [routerLink]="['/home/fashion']" (click)="logout()">Logout</a>
          <!-- Add more dropdown items as needed -->
        </div>
      </li>
    </div>
  </div>
  <div class="container-fluid  container-width">
    <div class="row row-width">
      <div class="col-sm-12 col-md-12 col-12 col-width">
        <div class="row justify-content-between">
          <div class="col-md-2 col-sm-3 col-8">
            <div class="row align-items-center">
              <div class="col-4">
                <app-left-menu></app-left-menu>
              </div>
              <div class="col-8 d-flex justify-content-center ">
                <a routerLink="/home/fashion" id="headerlogo">
                  <img [src]="storeDetails?.company.logoAttachment?.data" style="width: 5rem;" class="img-fluid"
                    alt="logo">
                </a>
              </div>
            </div>
          </div>
          <!--<i class="fa-solid fa-caret-down"></i>  (click)="categoryDropdown()-->
          <div class="col-md-1 col-sm-2 col-4 d-flex align-items-center ">
            <li role="button" [nzTrigger]="'click'" nz-dropdown [nzDropdownMenu]="menu">Category <span nz-icon nzType="down"></span></li>
            <nz-dropdown-menu #menu="nzDropdownMenu" >
              <div nz-menu nzSelectable class="w-75 mt-3">
                <div class="row  m-0 p-0 row-cols-3 ">
                <ng-container *ngFor="let category of  categoryList">
                    <div nz-menu-item class="col" (click)="selectCategory(category.id)">{{category.name | titlecase}}</div>
                    </ng-container>
                  </div>
                </div>
            </nz-dropdown-menu>
          </div>
          <div class="col-md-5 col-12 col-sm-3 col-6 d-flex align-items-center mt-2 " (click)="handleClick()">
            <div class="ng-autocomplete w-100">
              <ng-autocomplete #auto [data]="searchItem" class="auto-complete " [searchKeyword]="keyword"
                [debounceTime]="500" placeholder="Select for Products" (selected)='selectEvent($event)'
                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]=" itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            </div>
          </div>

          <!-- <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
          </div> -->

          <ng-template #itemTemplate let-item>

            <a class="d-block w-100" role="button" style="padding: 7px 15px;" [innerHTML]="item.name"></a>

          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>

          <div class="col-md-4  col-sm-4 d-none d-sm-block d-md-block " style="height: 4rem;">
            <div>
              <!-- class="menu-right pull-right w-100 h-100 " -->
              <ng-container *ngIf="!isLoggedIn">
                <div class="col-md-12 menu-right-align text-end p-0" >
                  <div class="row h-100">
                    <div class="col-12 ">
                      <div role="button" class="d-flex flex-column align-items-end justify-content-center  h-100"
                        style="color:black;font-weight:500;" (click)='showAddModal()'>
                        <img src="../../../../assets/images/icon/avatar.png"
                          style="width: 1.5rem;height: 1.5rem;margin-right: .5rem;">
                        <span>Login
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="col-md-12 col-sm-12 text-center menu-right-align" *ngIf="isLoggedIn">
                <ul class="header-dropdown  d-flex justify-content-evenly ">
                  <li class="compare mobile-wishlist text-left p-1 menu-right-size ">
                    <a style="color: black;" [routerLink]="['/shop/wishlist']">
                      <span class="position-relative">
                        <img style="width: 1.5rem;" src="../../../../assets/images/Wishlist.svg">
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{myWhistListLength}}</span>
                      </span>&nbsp; Wishlist </a>
                  </li>
                  <li class="mobile-wishlist  p-1 menu-right-size ">
                    <a style="color: black;" [routerLink]="['/shop/cart']">
                      <span class="position-relative">
                        <img style="width: 1.5rem;" src="../../../../assets/images/Cart.svg">
                        <span
                          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{myCartLengthUpdate}}</span>
                      </span>&nbsp;Cart
                    </a>

                  </li>

                  <li class="nav-item dropdown mobile-wishlist  p-1 menu-right-size ">
                    <a style="color: black;"
                      class="d-flex flex-wrap align-items-center justify-content-center  dropdown-toggle" href="#"
                      role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">

                      <img style="width: 1.5rem;" src="../../../../assets/images/user.svg" alt="User Icon">
                      &nbsp;My Account
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">

                      <a class="dropdown-item" [routerLink]="['/home/myprofile']">My Profile</a>
                      <a class="dropdown-item" [routerLink]="['/home/myorder']">My Orders</a>
                      <!-- <a class="dropdown-item" >Notification</a> -->
                      <a class="dropdown-item" role="button" (click)="openWhatsapp()">24x7 Customer care</a>
                      <a class="dropdown-item" [routerLink]="['/home/fashion']" (click)="logout()">Logout</a>
                      <!-- Add more dropdown items as needed -->
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <!-- <app-menu></app-menu> -->
              </div>
              <div>
                <!-- <app-settings></app-settings> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <hr style="margin: 0%;"> -->
</header>

<!-- end -->