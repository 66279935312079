import { Moment } from "moment";
import { AbstractResponse } from "./abstract-response.model";

export class Mycart extends AbstractResponse {
  userId: string;
  itemId: string;
  quantity: number;
}

export class MycartCreate {
  storeId: string;
  userId: string;
  itemId: string;
  quantity: number;
}

export class Mycartget extends AbstractResponse {
  createdBy: string;
  createdDateTime: Moment;
  id: string;
  item: {
    createdBy: string;
    createdDateTime: Moment;
    currentOpeningStock: number;
    discount: number;
    expireDate: string;
    finalPrice: number;
    id: string;
    minimumQty: number;
    name: string;
    storeId: string;
  };
  quantity: number;
}

export class myCartEdit {
  id: string;
  userId: string;
  itemId: string;
  storeId: string;
  quantity: number;
}
