import { Exclude, Type } from "class-transformer";
import { Page } from "./page.model";
export class GenericHttpResponse<T> {
  @Exclude()
  private type: Function;

  responseMessage: string;
  responseStatus: boolean;
  responseStatusCode: number;

  @Type((options) => {
    return (options.newObject as GenericHttpResponse<T>).type;
  })
  responseObject: T;

  id: any;
  item: any;
  body: any;
  quantity: any;


  constructor(type: any) {
    let isPageTypeObject = false;
    if (type && type instanceof Page) {
      isPageTypeObject = true;
    }
    if (isPageTypeObject) {
      this.responseObject = type;
    } else {
      this.type = type;
    }
  }
}
