import { HttpParams } from '@angular/common/http';

export const createMatrixOption = (req?: any) => {

    let options = '';
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'page' && key !== 'size') {
                options += ';' + key + '=' + req[key];
            }
        });
    }
    return options;
};
export const createMatrixOptionsss = (req?: any) => {

    let options = '';
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'pageNo' && key !== 'pageSize') {
                options += ';' + key + '=' + req[key];
            }
        });
    }
    return options;
};

export const createMatrixOptionss = (req?: any) => {

    let options = '';
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'page' && key !== 'size') {
                options += ';' + key + '=' + req[key];
            }
        });
    }
    return options;
};

export const createMatrixOptions = (req?: any) => {

    let options = '';
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'page' && key !== 'size') {
                options += '' + key + '=' + req[key];
            }
        });
    }
    return options;
};

export const createRequestOption = (req?: any, includeAll: boolean = false): HttpParams => {

    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {

            if (!includeAll) {
                if (key === 'page' || key === 'size') {
                    options = options.set(key, req[key]);
                }
            } else {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach(val => {
                options = options.append('sort', val);
            });
        }
    }

    return options;
};
export const createRequestOptions = (req?: any, includeAll: boolean = false): HttpParams => {

    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {

            if (!includeAll) {
                if (key === 'pageNo' || key === 'pageSize') {
                    options = options.set(key, req[key]);
                }
            } else {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach(val => {
                options = options.append('sort', val);
            });
        }
    }


    return options;
};


export const createQueryRequest = (req): HttpParams => {

    let options: HttpParams = new HttpParams();

    if (req) {
        Object.keys(req).forEach(key => {

            options = options.set(key, req[key]);
        }
        )
    }
    return options;

}

