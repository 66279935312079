
// 
// 
import { PreDefinedCustomer } from 'src/app/core/enum/customer.enum';
import { DatatableColumn } from '../models/datatable-column.model';
import { Permission } from '../models/permission.model';
import { Role } from '../models/role.model';
import { Store, StorePartialSummary } from '../models/store.model';

import { filterById, sortBy } from './matrix-filter.util';



import { DiscountType } from '../models/discount-type.model';

export function getJoinIds(arr: any[]): string {
  return arr.map(obj => obj.id).join();
}

export function getArrayIds(arr: any[]): string[] {
  return arr.map(obj => obj.id);
}

export function getJoinStringArray(arr: string[]): string {
  return arr.join();
}

export function removeDuplicatesFromArray(arr: any[]): any[] {
  return arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
}

export function removeDuplicatesFromStringArray(arr: String[]): String[] {
  return Array.from(new Set(arr));
}

export function stringArrayToCommaSeparatedString(stringArr: String[]) {
  return stringArr.join();
}

export function getPagedRequestWithSortOrder() {
  return { pagination: true, sort: sortBy('sortOrder', 'asc') };
}

export function isNumeric(value: string | number): boolean {
  return ((value != null || value != undefined) &&
    (value.toString().trim().length > 0) &&
    !isNaN(Number(value.toString())));
}

export function permissionListToMap(permissions: Permission[]): Map<String, Permission[]> {
  const keys = [];
  const map = new Map<String, Permission[]>();
  permissions.forEach((e) => {
    if (keys.indexOf(e.target) === -1) {
      keys.push(e.target);
    }
  });

  if (keys) {
    keys.forEach(element => {
      const data = [];
      permissions.forEach((e) => {
        if (element === e.target) {
          data.push(e);
        }
      });
      map.set(element, data);
    });
    return map;
  }

  return undefined;

}

export function toNumber(value: any): number {
  if (value === undefined || value === null || !isNumeric(value)) {
    return 0;
  }
  return Number(value);
}

export function isColumnToggled(columns: DatatableColumn[], columnName: string): boolean {
  return this.allColumns.find(column => column.name == columnName).checked;
}

export function getAllDatatableToggledColumnNames(columns: DatatableColumn[]): string[] {
  return columns.filter(column => column.checked === true).map(column => column.name);
}

export function getUnitItemDisplayValue(value: string, abbreviation: string): string {
  return abbreviation ? `${value} ${abbreviation}` : value;
}

export function getArrayLastElementIndex(array: any[]): number {
  return array.length - 1;
}

export function getAllDatatableToggledTranslatedColumnNames(columns: DatatableColumn[], excludeColumns: string[]): string[] {
  return columns.filter(column => !excludeColumns.includes(column.name)).map(column => column.translatedName);
}






export function getUnPagedRequest() {
  return { pagination: false };
}

export function getUnPagedRequests() {
  return { pagination: false, page: 1, size: 10 };
}

export function getUnPagedAndActiveRequest() {
  return { active: true, pagination: false };
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}



export function getUpdatedDiscountTypes(selectedStore: StorePartialSummary, discountTypeResponse: DiscountType[]): DiscountType[] {
  const updatedDiscountTypes = [];
  const discountypePercentage = { ...discountTypeResponse.find(discountType => discountType.name === "PERCENTAGE") };
  discountypePercentage.description = discountypePercentage.description + `(%)`;
  const discountypeFixed = { ...discountTypeResponse.find(discountType => discountType.name === "FIXED") };
  // discountypeFixed.description = discountypeFixed.description + `(${selectedStore.getCurrencySymbol()})`;
  updatedDiscountTypes.push(discountypeFixed);
  updatedDiscountTypes.push(discountypePercentage);
  return updatedDiscountTypes;
}





export function isStringEmpty(str: string): boolean {
  if (!str) {
    return true;
  }
  return !!str && str.trim().length == 0;
}

export function isStringNotEmpty(str: string): boolean {
  return str && str.trim().length > 0;
}


export function isArrayEmpty(array: any[]): boolean {
  return !array || array.length == 0;
}

export function isArrayNotEmpty(array: any[]): boolean {
  return array && array.length > 0;
}

export function base64ToBlob(data, mimeType: string): Blob {
  // Replace the data to remove data and base64 string to encode the uri
  const findStr = `data:${mimeType};base64,`;
  // /^data:image\/(png|jpeg);base64,/
  data = data.replace(findStr, "");
  const byteString = window.atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: mimeType });
  return blob;
}



export function toFixedNumber(value: number, decimalPlaces: number): number {
  return toNumber(toNumber(value).toFixed(decimalPlaces));
}

// export function getItemStockLabel(item: Item | ItemPartialSummary): string {
//   if (item.isFromIdealMeasurement()) {
//     return getUnitItemDisplayValue(item.currentOpeningStock?.toString(), item.unitIdealMeasurement?.abbreviation);
//   }
//   return item.currentOpeningStock?.toString();
// }

export function getUnitPricePerDisplayValue(price: string, abbreviation: string): string {
  return abbreviation ? `${price}/${abbreviation}` : price;
}
