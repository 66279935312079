import { AbstractResponse } from "./abstract-response.model";

export class Mywishlist extends AbstractResponse {
  ecomUserId: number;
  itemId: string;
}

export class MywishlistCreate {
  userId: string;
  itemId: string;
  storeId: string;
}

export class Mywishlistget extends AbstractResponse {
  item: {
    currentOpeningStock: number;
    discount: number;
    expireDate: string;
    finalPrice: number;
    id: string;
    imageAttachment: {
      url: string;
    };
    minimumQty: number;
    name: string;
  };
}
