import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemCategoryService } from 'src/app/core/service/item-category.service';
import { StandardCategory } from 'src/app/core/models/item-category.model';
import { Page } from 'src/app/core/models/page.model';
import { GenericHttpResponse } from 'src/app/core/models/generic-http-response.model';
import { BrandService } from 'src/app/core/service/brand.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public menuItems: Menu[];

  loadDataSubscription: Subscription;
  StandardCategory: GenericHttpResponse<Page<StandardCategory[]>>;
  currentPage=0;
 

  constructor(private router: Router,
     public navServices: NavService,
     private itemBrandService:BrandService,
     private itemCategory:ItemCategoryService,
     private spinnerService:SpinnerService,
     private itemCategoryService: ItemCategoryService) {
    this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {


  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
 
    this.reload();

  }

  reload(){

    // this.standardCategory();
    this.loadCategory(environment.storeId, this.currentPage);
      this.loadBrand(environment.storeId, this.currentPage);
  }


  private standardCategory(searchMatrixParams = undefined) {

    
    this.loadDataSubscription = this.itemCategoryService.getAllCategory( true).subscribe({
      next: (res) => {
        this.StandardCategory = res.body.responseObject;
        // this.totalItems = res.responseObject.totalElements;
        // this.fillDatatable();
        //  this.reload1();

      },
      // complete: () => this.stopLoadingIndicator(),
      // error: (err) => this.subscribeError(err)
    });
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(category) {
    if(window.innerWidth > 1200 && category){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  categoryItems(id){

    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;



    this.router.navigateByUrl(`/shop/category-Store/${id}`)
  }

  loadingIndicator = false;
  brandList:any;

  loadBrand(storeId, currentPage: number = 0, searchMatrixParams = undefined) {
    this.loadingIndicator = true;
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams;
    } else {
      // matrixParams = this.getRequestParam(currentPage);
    }
    this.itemBrandService.getAll(storeId, matrixParams, true).subscribe({
      next: (res) => {
        this.brandList = res.body.responseObject;
        // this.brandList=_.sortBy(this.brandList,'name')
        // this.brandList.sort((a,b)=>a.name.localeCompare(b.name))
        this.brandList.sort((a,b)=>a.name.trim().localeCompare(b.name.trim()));
      },
      complete: () => this.stopLoadingIndicator(),
      error: (err) => this.subscribeError(err)
    });
  }

  private stopLoadingIndicator() {
    this.loadingIndicator = false;
    this.spinnerService.hide();
  }

  private subscribeError(err) {
    this.stopLoadingIndicator();
  }

  categoryList: any;
  // brandList: any;
  loadCategory(storeId, currentPage: number = 1, searchMatrixParams = undefined) {
    this.spinnerService.show();
    this.loadingIndicator = true;
    let matrixParams = undefined;
    if (searchMatrixParams) {
      matrixParams = searchMatrixParams;
    } else {
      // matrixParams = this.getRequestParam(currentPage);
    }
    this.itemCategory.getAll(storeId, matrixParams, true).subscribe({
      next: (res) => {
        this.categoryList = res.body.responseObject;
        this.categoryList.sort((a,b)=>a.name.trim().localeCompare(b.name.trim()))
        this.spinnerService.hide();
      },
      complete: () => this.stopLoadingIndicator(),
      error: (err) => this.subscribeError(err)
    });
  }

  selectCategory(categoryId){

    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;

    setTimeout(() => { this.itemCategoryService.sendCategoryId(categoryId); }, 2000);

    // this.itemCategoryService.sendCategoryId(categoryId);
    this.router.navigateByUrl('/home/fashion');

  }

  selectBrand(brandId){
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;

    setTimeout(() => { this.itemBrandService.sendBrandId(brandId); }, 2000);

    this.itemBrandService.sendBrandId(brandId);
    this.router.navigateByUrl('/home/fashion');

  }

}
