import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertIcon, SweetAlertInput } from 'sweetalert2';
import { Store, StorePartialSummary } from '../models/store.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private loginSound = new Audio('assets/sounds/login.wav');
  private paymentAudio = new Audio('assets/sounds/payment.wav');
  private errorAudio = new Audio('assets/sounds/error.wav');
  private salesHoldAudio = new Audio('assets/sounds/sales-hold.wav');
  private salesPurchaseAudio = new Audio('assets/sounds/sales-purchase.wav');
  private addItemAudio = new Audio('assets/sounds/add-item.wav');
  private removeItemAudio = new Audio('assets/sounds/remove-item.wav');
  private internalServerErrorAudio = new Audio('assets/sounds/internal-server-error.wav');

  // toastrPositionClass = 'toast-bottom-right';
  toastrPositionClass = 'toast-top-center';

  valueToPass: string;
  
  constructor(private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,) { }

  successAlert(message: string, title = 'Success', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  loginAlert(message: string, title = 'Congratulations! now you part of our e-commerce community Let shop and save together', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  otpAlert(message: string, title = 'Account Activation: Use the OTP sent to your email to activate your account.', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  forgototpAlert(message: string, title = 'OTP Sent by Email Successfully', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  connectAlert(message: string, title = '', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

ChangeloginAlert(title = 'Are you sure?', text = "Please sign out from the other devices and log in here. Thank you!", confirmButtonText = 'Yes, do it!') {

    return Swal.fire({
      // title: "Select company",
      text: text,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',

    });

  }

  storeAlert(title = 'Are you sure?', text = "Do you want to change the store to "+title+" ?", confirmButtonText = 'Yes, do it!') {
    return Swal.fire({
      title: "Select Store",
      text: text,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }

  companyAlert(title = 'Are you sure?', text = "Do you want to change the Company to "+title+" ?", confirmButtonText = 'Yes, do it!') {
    return Swal.fire({
      title: "Select company",
      text: text,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }

  successAlertCustomer(message: string, title = 'User Found', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  successAlertExpense(message: string, title = ' ', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.success(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  infoAlert(message: string, title = 'Info', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.info(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  warningAlert(message: string, title = 'Warning!', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.warning(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  errorAlert(message: string, title = 'Error', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.error(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  disconnectAlert(message: string, title = '', progressBar = false, closeButton = false, alertSound = false) {
    this.toastr.error(message, title, {
      positionClass: this.toastrPositionClass,
      progressBar: progressBar,
      closeButton: closeButton
    });
  }

  sweetConfirmationAlert(title = 'Are you sure?', text = "This action cannot be reverted!", confirmButtonText = 'Yes, do it!') {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
    });
  }


  sweetErrorAlert(text = "Something went wrong!", title = 'Oops...') {
    return Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }

  sweetInputAlert(title: string, input: SweetAlertInput, inputPlaceholder: string = '', confirmButtonText: string = 'Ok', icon: SweetAlertIcon = 'warning') {
    return Swal.fire({
      title: title,
      input: input,
      icon: icon,
      inputPlaceholder: inputPlaceholder,
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      showLoaderOnConfirm: false,
      allowOutsideClick: false,
    });
  }


  expireAlert(title: string, inputPlaceholder: string = '', confirmButtonText: string = 'Ok', icon: SweetAlertIcon = 'warning') {

    return Swal.fire({

      title: title,

      icon: icon,

      inputPlaceholder: inputPlaceholder,

      inputAttributes: {

        autocapitalize: 'off',

      },

      showCancelButton: true,

      confirmButtonText: confirmButtonText,

      showLoaderOnConfirm: false,

      allowOutsideClick: false,

    });

  }
  
  sweetPreviewSmsAlert(title: string, mobileNumberPlaceholder: string, messageNumberPlaceholder: string, confirmButtonText: string = 'Ok', icon: SweetAlertIcon = 'warning') {
    return Swal.fire({
      title: title,
      html:
        `<input id="mobileNumber" class="swal2-input" tabIndex="3" placeholder="${mobileNumberPlaceholder}">` +
        `<input id="message" class="swal2-input" tabIndex="3" placeholder="${messageNumberPlaceholder}">`,
      icon: icon,
      inputAttributes: {
        autocapitalize: 'off',

      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      showLoaderOnConfirm: false,
      allowOutsideClick: false,
      preConfirm: () => {
        const mobileNumber = document.getElementById('mobileNumber') as any;
        const message = document.getElementById('message') as any;
        return [
          mobileNumber.value,
          message.value
        ]
      }
    });
  }

  // Alert sounds start

  paymentPaidAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.paymentAudio);
    }
  }

  paymentErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
  }

  saleHoldAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.salesHoldAudio);
    }
  }

  saleHoldErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
  }

  saleAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.salesPurchaseAudio);
    }
  }

  saleErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
  }

  saleReturnAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.salesPurchaseAudio);
    }
  }

  saleReturnErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
  }

  purchaseAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.salesPurchaseAudio);
    }
  }

  purchaseErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
    this.SpinnerService.hide();
  }

  purchaseReturnAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.salesPurchaseAudio);
    }
  }

  purchaseReturnErrorAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.errorAudio);
    }
  }

  plusQtyAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.addItemAudio);
    }
  }

  minusQtyAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.removeItemAudio);
    }
  }

  itemSelectAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.addItemAudio);
    }
  }

  itemDeSelectAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.removeItemAudio);
    }
  }

  loginAlertSound(store: StorePartialSummary) {
    if (store && !!store.alertSound) {
      this.playAudio(this.loginSound);
    }
  }

  internalServerErrorAlertSound() {
    this.playAudio(this.internalServerErrorAudio);
  }

  // Alert sounds end

  private playAudio(audio: HTMLAudioElement) {
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }
}
