import { COMPANY_COLUMNS } from '../models/company.model';
import { COUNTRY_COLUMNS } from '../models/country.model';
import { GlobalFilter } from '../models/global-filter.model';
import { ROLE_COLUMNS } from '../models/role.model';
import { STORE_COLUMNS } from '../models/store.model';
import { isNumeric } from './common.utils';
import { OCC_COLUMNS } from '../models/occac.model';



export const getInitialRequestParamsFromRequest = (req: any) => {
    let matrixParams = {};
    // if (req.page) {
    //     matrixParams['page'] = req.page;
    // }
    // if (req.size) {
    //     matrixParams['size'] = req.size;
    // }
    // if (req.sort) {
    //     matrixParams['sort'] = req.sort;
    // }
    // if (req.pagination || req.pagination != undefined) {
    //     matrixParams['pagination'] = req.pagination;
    // }
    // if (req.active) {
    //     // Add active parameter with and operator
    //     matrixParams['active_AAO'] = req.active;
    // }

    // matrixParams['isRawItem'] = 'no';

    return matrixParams;
}
export const getInitialRequestParamsFromRequestItem = (req: any) => {
    let matrixParams = {};
    if (req.page) {
        matrixParams['page'] = req.page;
    }
    if (req.size) {
        matrixParams['size'] = req.size;
    }
    if (req.sort) {
        matrixParams['sort'] = req.sort;
    }
    if (req.pagination || req.pagination != undefined) {
        matrixParams['pagination'] = req.pagination;
    }
    if (req.active) {
        // Add active parameter with and operator
        matrixParams['active_AAO'] = req.active;
    }

    // matrixParams['isRawItem'] = 'no';

    return matrixParams;
}

export const getInitialRequestParamsFromRequestss = (req: any) => {
    let matrixParams = {};
    //  if (req.pageNo) {
    //     matrixParams['pageNo'] = req.pageNo;
    // }
    // if (req.pageSize) {
    //     matrixParams['pageSize'] = req.pageSize;
    // }
    // if (req.sort) {
    //     matrixParams['sort'] = req.sort;
    // }

    if (req.page) {
        matrixParams['page'] = req.page;
    }
    if (req.size) {
        matrixParams['size'] = req.size;
    }
    if (req.sort) {
        matrixParams['sort'] = req.sort;
    }
    if (req.pagination || req.pagination != undefined) {
        matrixParams['pagination'] = req.pagination;
    }
    // if (req.active) {
    //     // Add active parameter with and operator
    //     matrixParams['active_AAO'] = req.active;
    // }

    // matrixParams['isRawItem'] = 'no';


    return matrixParams;
}


export const getInitialRequestParamsFromRequested = (req: any) => {
    let matrixParams = {};
    if (req.page) {
        matrixParams['page'] = req.page;
    }
    if (req.size) {
        matrixParams['size'] = req.size;
    }
    if (req.sort) {
        matrixParams['sort'] = req.sort;
    }
    if (req.pagination || req.pagination != undefined) {
        matrixParams['pagination'] = req.pagination;
    }
    // if (req.active) {
    //     // Add active parameter with and operator
    //     matrixParams['active_AAO'] = req.active;
    // }

    // matrixParams['isRawItem'] = 'no';

    return matrixParams;
}

export const getInitialRequestParamsFromRequests = (req: any) => {
    let matrixParams = {};
    if (req.page) {
        matrixParams['page'] = req.page;
    }
    if (req.size) {
        matrixParams['size'] = req.size;
    }
    if (req.sort) {
        matrixParams['sort'] = req.sort;
    }
    if (req.pagination || req.pagination != undefined) {
        matrixParams['pagination'] = req.pagination;
    }
    if (req.active) {
        // Add active parameter with and operator
        matrixParams['active_AAO'] = req.active;
    }

    matrixParams['isRawItem_AAIO'] = 'no';

    return matrixParams;
}

export const sortBy = (predicate = 'createdDateTime', order = 'desc') => {
    const result = [predicate + ',' + order];
    return result;
}

export const sortBysd = (predicate = 'createdDateTime', order = 'desc') => {
    const result = [predicate + ',' + order];
    return result;
}

export const countryFilter = (req: any, value, keys: string[]) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (keys.includes(COUNTRY_COLUMNS.NAME)) {
        matrixParams['name_like'] = value;
    }
    if (keys.includes(COUNTRY_COLUMNS.IDC)) {
        matrixParams['idc_like'] = value;
    }
    if (keys.includes(COUNTRY_COLUMNS.CODE)) {
        matrixParams['code_like'] = value;
    }
    matrixParams['orFilter'] = true;
    return matrixParams;
}



export const stateFilterByCountryId = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['country.id'] = value;
    }
    return matrixParams;
}



export const filterByUnitTypeIds = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['unitType.id_AAIO'] = value;
    }
    return matrixParams;
}




export const companyFilter = (req: any, value, keys: string[], filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        if (keys.includes(COMPANY_COLUMNS.NAME)) {
            matrixParams['name_like'] = value;
        }
        if (keys.includes(COMPANY_COLUMNS.CODE)) {
            matrixParams['companyCode.code_like'] = value;
        }

        if (keys.includes(COMPANY_COLUMNS.ADDRESS)) {
            matrixParams['address.city_like'] = value;
            matrixParams['address.state.country.name_like'] = value;
            matrixParams['address.state.name_like'] = value;
        }

        if (keys.includes(COMPANY_COLUMNS.USER_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['userLimit'] = value;
            }
        }

        if (keys.includes(COMPANY_COLUMNS.ITEM_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['itemLimit'] = value;
            }
        }

        if (keys.includes(COMPANY_COLUMNS.STORE_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['storeLimit'] = value;
            }
        }
        matrixParams['orFilter'] = true;
    }
    if (filter) {
        if (filter.companyId) {
            matrixParams['id_AAIO'] = filter.companyId;
            //matrixParams['id'] = filter.companyId;
        }
    }

    return matrixParams;
}

export const configFilter = (req: any) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);



    return matrixParams;
}

export const storeFilter = (req: any, value, keys: string[], filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {

        if (keys.includes(STORE_COLUMNS.CODE)) {
            matrixParams['storeCode.code_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.NAME)) {
            matrixParams['name_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.ADDRESS)) {
            matrixParams['address.address_like'] = value;
            matrixParams['address.city_like'] = value;
            matrixParams['address.state.country.name_like'] = value;
            matrixParams['address.state.name_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.USER_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['userLimit'] = value;
            }
        }

        if (keys.includes(STORE_COLUMNS.ITEM_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['itemLimit'] = value;
            }
        }

        if (keys.includes(STORE_COLUMNS.COMPANY)) {
            matrixParams['company.name_like'] = value;
        }
        matrixParams['orFilter'] = true;
    }
    if (filter) {
        // Here give storeId as first priority
        if (!!filter.storeId) {
            matrixParams['id_AAIO'] = filter.storeId;
        }
        else if (filter.companyId) {
            matrixParams['company.id_AAIO'] = filter.companyId;
        }

    }

    return matrixParams;
}

export const storeFilters = (req: any, value, keys: string[], filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {

        if (keys.includes(STORE_COLUMNS.CODE)) {
            matrixParams['storeCode.code_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.NAME)) {
            matrixParams['name_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.ADDRESS)) {
            matrixParams['address.address_like'] = value;
            matrixParams['address.city_like'] = value;
            matrixParams['address.state.country.name_like'] = value;
            matrixParams['address.state.name_like'] = value;
        }

        if (keys.includes(STORE_COLUMNS.USER_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['userLimit'] = value;
            }
        }

        if (keys.includes(STORE_COLUMNS.ITEM_LIMIT)) {
            if (isNumeric(value)) {
                matrixParams['itemLimit'] = value;
            }
        }

        if (keys.includes(STORE_COLUMNS.COMPANY)) {
            matrixParams['company.name_like'] = value;
        }
        matrixParams['orFilter'] = true;
    }
    if (filter) {
        // Here give storeId as first priority
        if (!!filter.storeId) {
            matrixParams['id_AAIO'] = filter.storeId;
        }
        else if (filter.companyId) {
            matrixParams['id'] = filter.companyId;
        } else if (filter.storeId) {
            matrixParams['store.id'] = filter.storeId;
        }

    }

    return matrixParams;
}

export const storeFilterByCompanyIds = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['company.id_AAIO'] = value;
    }
    return matrixParams;
}


export const filterById = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['id'] = value;
    }

    return matrixParams;
}

export const filterByPurchaseId = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['purchase.id'] = value;
    }

    return matrixParams;
}

export const filterByPurchaseReturnId = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['purchaseReturn.id'] = value;
    }

    return matrixParams;
}

export const filterBySaleId = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['sale.id'] = value;
    }

    return matrixParams;
}

export const filterBySaleReturnId = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['saleReturn.id'] = value;
    }

    return matrixParams;
}

export const filterByStoreIds = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['store.id'] = value;
    }
    return matrixParams;
}

export const filterByCompanyIds = (req: any, value) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['company.id_AAIO'] = value;
    }
    return matrixParams;
}



export const roleFilter = (req: any, value, keys: string[]) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        if (keys.includes(ROLE_COLUMNS.NAME)) {
            matrixParams['name_like'] = value;
        }
    }

    matrixParams['orFilter'] = true;
    return matrixParams;
}


export const employeeFilters = (req: any, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const brandFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(BRAND_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }
    //     if (keys.includes(BRAND_COLUMNS.CODE)) {
    //         matrixParams['brandCode.code_like'] = value;
    //     }
    //     if (keys.includes(BRAND_COLUMNS.DESCRIPTION)) {
    //         matrixParams['description_like'] = value;
    //     }
    //     if (keys.includes(BRAND_COLUMNS.STORE)) {
    //         matrixParams['store.name_like'] = value;
    //     }
    //     if (keys.includes(BRAND_COLUMNS.COMPANY)) {
    //         matrixParams['store.company.name_like'] = value;
    //     }
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const itemCategoryFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const itemDetailFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const myCartDetailFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const myOrderDetailFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const addressFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const locationFilter = (req: any, city: string, postCode: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (city) {
        matrixParams['store.id_AAIO'] = city;
    }
    if (postCode) {
        matrixParams['store.id_AAIO'] = postCode;
    }
    // }
    return matrixParams;
}


export const searchItemFilter = (req: any,) => {
    let matrixParams = getInitialRequestParamsFromRequestss(req);
    return matrixParams;
}

export const searchFilter = (req: any,) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    // if (city) {
    //     matrixParams['store.id_AAIO'] = city;
    // }
    // if (postCode) {
    //     matrixParams['store.id_AAIO'] = postCode;
    // }
    // }
    return matrixParams;
}

export const topitemFilter = (req: any, city: string, postCode: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (city) {
        matrixParams['store.id_AAIO'] = city;
    }
    if (postCode) {
        matrixParams['store.id_AAIO'] = postCode;
    }
    // }
    return matrixParams;
}

export const topstoreFilter = (req: any, city: string, postCode: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (city) {
        matrixParams['store.id_AAIO'] = city;
    }
    if (postCode) {
        matrixParams['store.id_AAIO'] = postCode;
    }
    // }
    return matrixParams;
}

export const itemFilter = (req: any, storeId: string, brandId?: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);

    // if (value) {

    //     if (keys.includes(ITEM_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }
    //     if (keys.includes(ITEM_COLUMNS.CODE)) {
    //         matrixParams['itemCode.code_like'] = value;
    //         matrixParams['barcode_like'] = value;
    //     }
    //     // if (keys.includes(ITEM_COLUMNS.BARCODE)) {
    //     //     matrixParams['barcode_like'] = value;
    //     // }
    //     if (keys.includes(ITEM_COLUMNS.CATEGORY)) {
    //         matrixParams['category.name_like'] = value;
    //     }
    //     if (keys.includes(ITEM_COLUMNS.UNIT)) {
    //         matrixParams['unit.name_like'] = value;
    //     }

    //     if (isNumeric(value)) {
    //         matrixParams['currentOpeningStock'] = value;
    //         matrixParams['minimumQty'] = value;
    //         matrixParams['purchasePrice'] = value;
    //         matrixParams['finalPrice'] = value;
    //     }

    //     if (keys.includes(ITEM_COLUMNS.STORE)) {
    //         matrixParams['store.name_like'] = value;
    //     }
    //     if (keys.includes(ITEM_COLUMNS.COMPANY)) {
    //         matrixParams['store.company.name_like'] = value;
    //     }
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }

    if (brandId) {
        matrixParams['brand.id_AAIO'] = brandId;
    }
    return matrixParams;
}

export const categoryItemFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}

export const categoryStoreFilter = (req: any, storeId: string) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    // if (value) {
    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.NAME)) {
    //         matrixParams['name_like'] = value;
    //     }

    //     if (keys.includes(ITEM_CATEGORY_COLUMNS.CODE)) {
    //         matrixParams['categoryCode.code_like'] = value;
    //     }
    //     //matrixParams['store.category.name_like'] = value;
    //     //matrixParams['store.name_like'] = value;
    //     matrixParams['orFilter'] = true;
    // }

    // if (filter) {
    //     if (filter.companyId) {
    //         matrixParams['store.company.id_AAIO'] = filter.companyId;
    //     }
    if (storeId) {
        matrixParams['store.id_AAIO'] = storeId;
    }
    // }
    return matrixParams;
}






export const occFilter = (req: any, value, keys: string[], filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {

        // if (keys.includes(OCC_COLUMNS.CODE)) {
        //     matrixParams['code_like'] = value;
        // }
        if (keys.includes(OCC_COLUMNS.PARTICULARS)) {
            matrixParams['particulars_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.CEEQUENO)) {
            matrixParams['chequeNo_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.TYPE)) {
            matrixParams['type_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.BALANCE)) {
            matrixParams['balance_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.APPROVAL1)) {
            matrixParams['approvalStatus1_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.APPROVAL2)) {
            matrixParams['approvalStatus2_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.STORE)) {
            matrixParams['store.name_like'] = value;
        }
        if (keys.includes(OCC_COLUMNS.COMPANY)) {
            matrixParams['store.company.name_like'] = value;
        }
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}





export const itemBrandFilter = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['brand.id'] = value;
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const itemBrandAndCategoryFilter = (req: any, brandId, categoryId, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequests(req);
    if (brandId) {
        matrixParams['brand.id'] = brandId;
    }
    if (categoryId) {
        matrixParams['category.id'] = categoryId;
    }
    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }

    }
    return matrixParams;
}

export const itemBrandAndCategoryFilters = (req: any, brandId, categoryId, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequests(req);
    if (brandId) {
        matrixParams['brand.id'] = brandId;
    }
    if (categoryId) {
        matrixParams['category.id'] = categoryId;
    }
    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }

    }

    matrixParams['isRawItem'] = 'no';
    return matrixParams;
}

export const itemBrand = (req: any, Letter, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (Letter && filter) {
        matrixParams['name_like'] = Letter;
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const itemLetter = (req: any, Letter, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (Letter && filter) {
        matrixParams['name_startswith'] = Letter;
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const itemBrands = (req: any, Letter, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (Letter && filter) {
        matrixParams['name_startswith'] = Letter;
        matrixParams['itemCode.code_startswith'] = Letter;
        matrixParams['barcode_startswith'] = Letter;
        matrixParams['orFilter'] = true;
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}



export const saleFilters = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['payments.paymentMethod.id'] = value;
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}


export const itemCommonSearchFilters = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {

        matrixParams['name_like'] = value;


        matrixParams['customerCode.code_like'] = value;



        matrixParams['contactDetail.contactNumber_like'] = value;


        matrixParams['contactDetail.email_like'] = value;



        matrixParams['customerCode.code_like'] = value;


        matrixParams['store.name_like'] = value;


        matrixParams['store.company.name_like'] = value;

        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const tableCommonSearchFilters = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequested(req);
    if (value) {

        matrixParams['name_like'] = value;

        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}

export const itemCommonSearchFilter = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        matrixParams['name_like'] = value;
        matrixParams['itemCode.code_like'] = value;
        matrixParams['barcode_like'] = value;
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }

    return matrixParams;
}



export const itemCommonSearchFiltersss = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequestItem(req);

    if (value) {
        matrixParams['name_like'] = value;
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        // if (filter.companyId) {
        //     matrixParams['store.company.id_AAIO'] = filter.companyId;
        // }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }
    return matrixParams;
}


export const itemSearchFilter = (req: any) => {

    let matrixParams = {};
    if (req.query) {
        matrixParams['query'] = req.query;
    }
    if (req.storeId) {
        matrixParams["storeId"] = [req.storeId]
    }
    return matrixParams;
}

export const itemCommonSearchFilterssss = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequest(req);
    if (value) {
        // matrixParams['name_startswith'] = value;
        // matrixParams['itemCode.code_like'] = value;
        matrixParams['barcode_startswith'] = value;
        // matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }

    return matrixParams;
}



export const itemCommonSearchFilterss = (req: any, value, filter?: GlobalFilter) => {
    let matrixParams = getInitialRequestParamsFromRequests(req);
    if (value) {
        matrixParams['name_like'] = value;
        matrixParams['itemCode.code_like'] = value;
        matrixParams['barcode_like'] = value;
        matrixParams['orFilter'] = true;
    }

    if (filter) {
        if (filter.companyId) {
            matrixParams['store.company.id_AAIO'] = filter.companyId;
        }
        if (filter.storeId) {
            matrixParams['store.id_AAIO'] = filter.storeId;
        }
    }

    matrixParams['isRawItem_AAIO'] = 'no';

    return matrixParams;
}




