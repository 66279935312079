import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Login, LoginCreate } from "src/app/core/models/login.model";
import { LoginService } from "src/app/core/service/login.service";
import { RegisterModalComponent } from "../authentication/register-modal/register-modal.component";
import { yangoosStorageKeys } from "../core/constant/app.constant";
import { StoreDetails } from "../core/models/store.model";
import { AlertService } from "../core/service/alert.service";
import { MycartService } from "../core/service/my-cart.service";
import { MywishlistService } from "../core/service/mywishlist.service";
import { StoreService } from "../core/service/store.service";

@Component({
  selector: "app-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"],
})
export class LoginModalComponent {
  loginForm: UntypedFormGroup;
  modalRef: NgbModalRef;
  passwordHidden: boolean = false;
  storeDetails: StoreDetails;
  storeId: string;
  myCartLengths: number;
  myWishListLength: number;

  @ViewChild("template", { static: true }) template: TemplateRef<any>;
  @ViewChild("registermodalRef", { static: true })
  registermodalRef: RegisterModalComponent;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    private loginService: LoginService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private mycartService: MycartService,
    private myWishListService: MywishlistService,
    private storeService: StoreService
  ) {
    this.storeId = localStorage.getItem("storeId");
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [undefined, Validators.required],
      password: [undefined, Validators.required],
    });

    setTimeout(() => {
      this.storeId = localStorage.getItem("storeId");
      this.getStoreDetails();
    }, 500);
  }

  getStoreDetails() {
    this.storeService.getStoreDetails(this.storeId).subscribe((res) => {
      this.storeDetails = res.responseObject;
    });
  }

  togglePasswordVisibility() {
    this.passwordHidden = !this.passwordHidden;
    const passwordInput = document.getElementById(
      "passwordInput"
    ) as HTMLInputElement;
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  }

  showRegisterModal() {
    this.modalRef.close();
    this.router.navigate(["/auth/register"]);
  }

  showAdd(): void {
    this.loginForm.reset();
    this.showModal();
  }

  private showModal(): void {
    this.modalRef = this.modalService.open(this.template, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
  }

  Login() {
    this.storeId = localStorage.getItem("storeId");
    this.SpinnerService.show();
    let login = new LoginCreate();
    login.email = this.loginForm.get("email").value;
    login.password = this.loginForm.get("password").value;
    login.storeId = this.storeId;
    localStorage.setItem("emailId", login.email);

    this.loginService.save(login, false).subscribe({
      next: (res) => {
        const userLogin: Login = res;

        this.showSuccessMessage("Login Successfully");
        localStorage.setItem("userId", res.id);
        localStorage.setItem(
          yangoosStorageKeys.authenticationToken,
          userLogin.token
        );
        localStorage.setItem("refreshToken", userLogin.refreshToken);
        this.loginService.loginStatus.next(true);
        this.getCartLength();
        this.getMyWishListLength();
        this.loginForm.reset();
        // Close the modal on successful login
        this.modalRef.close();

        // Hide the spinner after successful login
        this.SpinnerService.hide();
      },
      error: (err) => {
        if (
          err.status === 401 &&
          (err.error.message == "Bad credentials" ||
            err.error.message ==
            'Cannot invoke "com.ecommerce.yangoos.model.EcomUser.getId()" because "user" is null')
        ) {
          this.toastr.error("Please enter correct username and password");
        }

        if (
          err.error ==
          "User mail Account is Not Verified. So Complete Your Verification "
        ) {
          this.router.navigateByUrl(`auth/otp/reVerify/${login.email}`);
          this.modalRef.close();
        } else {
        }
        this.loginForm.reset();
        this.SpinnerService.hide();
      },
    });
  }

  private showSuccessMessage(message) {
    this.alertService.successAlert(message);
  }

  forget() {
    this.modalRef.close();
    this.router.navigate(["/auth/forget"]);
  }

  getCartLength() {
    const userId = localStorage.getItem("userId");
    this.mycartService.getAll(userId, true).subscribe({
      next: (res) => {
        this.myCartLengths = res.body.responseObject.length;
        this.mycartService.sendcartLength(this.myCartLengths);
      },
    });
  }

  getMyWishListLength() {
    const userId = localStorage.getItem("userId");
    this.myWishListService.getAll(userId, true).subscribe({
      next: (res) => {
        this.myWishListLength = res.body.responseObject.length;
        this.myWishListService.sendMyWishListLength(this.myWishListLength);
      },
    });
  }
}
