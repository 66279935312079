import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractRestService } from './abstract-rest.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GenericHttpResponse } from '../models/generic-http-response.model';
import { Observable } from 'rxjs';
import { searchDomain } from '../models/search-domain.model';

@Injectable({
  providedIn: 'root'
})
export class FindDominService extends AbstractRestService  {

  private apiUrl = `${environment.yangoosApiUrl}/api`;

  constructor(public httpClient: HttpClient, public loadingBarService: LoadingBarService) { 
    super(httpClient, loadingBarService);
  }

  searchByDomain(domain: string,noLoadingMask=false) : Observable<GenericHttpResponse<searchDomain>>{
    const searchUrl = `${this.apiUrl}/find-by-domain/${domain}`;
    return this.httpGet({
      url: searchUrl,
      noLoadingMask:noLoadingMask,
      returnType:new GenericHttpResponse<searchDomain>(searchDomain),
    })as Observable<GenericHttpResponse<searchDomain>>;
  }
}
