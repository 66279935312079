import { Component, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterCreate } from "src/app/core/models/register.model";
import { AlertService } from "src/app/core/service/alert.service";
import { RegisterService } from "src/app/core/service/register.service";

@Component({
  selector: "app-register-modal",
  templateUrl: "./register-modal.component.html",
  styleUrls: ["./register-modal.component.scss"],
})
export class RegisterModalComponent {
  registerForm: FormGroup;
  modalRef: NgbModalRef;
  passwordHidden: boolean = false;
  storeDetails: any;
  logo: string;
  storeId: string;

  @ViewChild("template", { static: true }) template: TemplateRef<any>;
  emailFormControl: FormControl<string>;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private registerService: RegisterService,
    private SpinnerService: NgxSpinnerService,
    private alertService: AlertService
  ) {
    this.storeId = localStorage.getItem("storeId");
    this.emailFormControl = new FormControl("", [
      Validators.required,
      Validators.email,
    ]);

    this.storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
    this.logo = this.storeDetails.company?.logoAttachment?.data;
  }

  ngOnInit() {
    this.storeId = localStorage.getItem('storeId');
    this.registerForm = this.fb.group({
      firstName: ["", [Validators.required,Validators.maxLength(100) ,Validators.pattern('[a-zA-Z ]*')]], //change by mahesh EC-60
      lastName: ["", [Validators.required,Validators.maxLength(100),Validators.pattern('[a-zA-Z ]*')]], //change by mahesh Ec-60
      email: ["", [Validators.required, Validators.email,Validators.maxLength(100),Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]], //change by mahesh Ec-60
      contactNumber: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{10}$")],
      ],
      // password: ['', Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/), Validators.minLength(8),], // Empty string as initial value
      gender: ["", Validators.required], // Empty string as initial value
      password: ["", [Validators.required,Validators.minLength(8),Validators.maxLength(20)]], //change by mahesh
    });
  }

  togglePasswordVisibility() {
    this.passwordHidden = !this.passwordHidden;
    const passwordInput = document.getElementById(
      "passwordInput"
    ) as HTMLInputElement;
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  }

  isFieldInvalid(field: string) {
    const control = this.registerForm.get(field);
    return control.invalid && control.touched;
  }

  showAdds(): void {
    this.showModal();
  }

  private showModal(): void {
    this.modalRef = this.modalService.open(this.template, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  registerCreate() {
    this.SpinnerService.show();
    let Register = new RegisterCreate();
    Register.contactNumber = this.registerForm.get("contactNumber").value;
    Register.email = this.registerForm.get("email").value;
    Register.firstName = this.registerForm.get("firstName").value;
    Register.gender = this.registerForm.get("gender").value;
    Register.lastName = this.registerForm.get("lastName").value;
    Register.password = this.registerForm.get("password").value;
    Register.storeId = this.storeId;

    this.registerService.save(Register, false).subscribe({
      next: (res) => {
        localStorage.setItem("registrationData", JSON.stringify(Register));
        this.showSuccessMessage(res.responseMessage);
        this.SpinnerService.hide(); // Hide the spinner on success
        this.router.navigateByUrl(`/auth/otp/verify/${this.registerForm.get("email").value}`
        );
      },
      error: (err) => {
        if (err.error == "User with the same email and storeId already exists") {
          this.alertService.errorAlert(
            "User is already registered for this Store"
          );
        } else {
          this.alertService.errorAlert(err.error.message);
        }
        this.SpinnerService.hide();
      },
    });
  }

  private showSuccessMessage(message) {
    this.alertService.otpAlert(message);
  }

  gender = [
    { id: "", name: "Select Gender" },
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
    { id: "others", name: "Others" },
  ];
}
