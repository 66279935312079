import { Exclude, Type } from "class-transformer";

export class Page<T> {
  @Exclude()
  private type: Function;

  totalPages: number;
  totalElements: number;
  @Type((options) => {
    return (options.newObject as Page<T>).type;
  })
  content: T;
  store: any;

  constructor(type: any) {
    this.type = type;
  }
}
