import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FindDominService } from './core/service/find-domin.service';
import { searchDomain } from './core/models/search-domain.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  domainData: searchDomain;
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private findByDomain: FindDominService,
    private router: Router) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
      const address = window.location.host;
      this.findByDomainAddress(address)
  }

  findByDomainAddress(address) {
    this.findByDomain.searchByDomain(address).subscribe({
      next: (res) => {
        this.domainData = res.responseObject
        localStorage.setItem('domainName', this.domainData.domainName);
        environment.storeId = this.domainData.storeId;
        localStorage.setItem('storeId', this.domainData.storeId)
        // this.router.navigateByUrl('/p')
      }
    })
  }

}
