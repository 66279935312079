import { AbstractResponse } from "./abstract-response.model";
// import { AddressUpdatebank } from "./address.model";
import { StoreBasicInfo } from "./store.model";
// import { StoreBasicInfo } from "./store.model";

export class Occ extends AbstractResponse {
  bankInitialBalance: string;
  particulars: string;
  chequeNo: string;
  type: string;
  balance: string;
  typeValue: string;
  plannedDate: string;
  revisedDate: string;
  approvalStatus1: string;
  approvalStatus2: string;
  store: StoreBasicInfo;
  code: string;
  active: boolean;
  // active: boolean;

  // getId?(): string {
  //     if (this.active) {
  //         return this.id;
  //     }
  //     return null;
  // }
}

export const OCC_COLUMNS = {
  CODE: "Code",
  PARTICULARS: "Particulars",
  CEEQUENO: "Cheque No",
  TYPE: "Type",
  BALANCE: "Balance",
  APPROVAL1: "Approval - 1",
  APPROVAL2: "Approval - 1",
  STORE: "Store",
  COMPANY: "Company",
  STATUS: "Status",
  ACTIONS: "Actions",
};

export class OccDatatable {
  id: string;
  // code: string;
  particulars: string;
  chequeNo: string;
  type: string;
  balance: string;
  approval1: string;
  approval2: string;
  company: string;
  store: string;
  active: boolean;
}

export class OccCreate {
  bankInitialBalance: string;
  particulars: string;
  chequeNo: string;
  type: string;
  typeValue: string;
  plannedDate: string;
  revisedDate: string;
  balance: string;
  approvalStatus1: string;
  approvalStatus2: string;
  storeId: string;
}

export class OccUpdate {
  id: string;
  bankInitialBalance: string;
  particulars: string;
  chequeNo: string;
  type: string;
  typeValue: string;
  plannedDate: string;
  revisedDate: string;
  balance: string;
  approvalStatus1: string;
  approvalStatus2: string;
  storeId: string;
}
