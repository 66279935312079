import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AbstractRestService } from './abstract-rest.service';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { GenericHttpResponse } from '../../core/models/generic-http-response.model';
import { Page } from '../../core/models/page.model';
import { Item, Itemcategorylist, Itemview } from '../models/item.model';
import { createMatrixOption, createQueryRequest, createRequestOption } from '../utils/http-request.util';
import { StoreCategory } from '../models/item-category.model';
@Injectable({
  providedIn: 'root'
})
export class ItemService extends AbstractRestService {

  readonly baseUrl: string = environment.yangoosApiUrl + '/api';

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }


  findById(id: string, noLoadingMask = false): Observable<GenericHttpResponse<Item>> {
    return this.httpGet({
      url: `${this.baseUrl}/item/${id}`,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Item>(Item),
    }) as Observable<GenericHttpResponse<Item>>;

  }



  getAll(id = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Item[]>>> {
    const metrixParams = createMatrixOption(params);

    const options = createRequestOption(params);

    let url = `${this.baseUrl}/item/by-store/${id}`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Item>>(new Page<Item>(Item)),
    }) as Observable<GenericHttpResponse<Page<Item[]>>>;

  }

  getAlls(id = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Itemview[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/item/${id}`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Itemview>>(new Page<Itemview>(Itemview)),
    }) as Observable<GenericHttpResponse<Page<Itemview[]>>>;

  }

  getAllItemCategory(id = undefined, city = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Itemcategorylist[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/item/by-category-and-city?categoryId=${id}&city=${city}`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Itemcategorylist>>(new Page<Itemcategorylist>(Itemcategorylist)),
    }) as Observable<GenericHttpResponse<Page<Itemcategorylist[]>>>;

  }

  getAllStoreCategory(id = undefined, city = undefined, params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<StoreCategory[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);

    let url = `${this.baseUrl}/by-standard-category-and-city?standardCategoryId=${id}&city=${city}`;

    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<StoreCategory>>(new Page<StoreCategory>(StoreCategory)),
    }) as Observable<GenericHttpResponse<Page<StoreCategory[]>>>;

  }

  // http://13.233.95.228:8081/api/item/get-all/listing;matrixVars=pagination%3Dfalse%3Bactive_AAO%3Dtrue%3Bname_startswith%3Dco%3BorFilter%3Dtrue%3Bstore.id_AAIO%3D451aa63b-8fd8-4444-a6ac-7c1154aec11d%3B?page=1&size=10&sort=
  searchItem(params = undefined, noLoadingMask = true): Observable<GenericHttpResponse<Page<Item[]>>> {
    const metrixParams = createMatrixOption(params);
    const options = createRequestOption(params);
    let url = `${this.baseUrl}/item/get-all/listing${metrixParams}`;
    return this.httpGet({
      url: url,
      params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Item>>(new Page<Item>(Item)),
    }) as Observable<GenericHttpResponse<Page<Item[]>>>;

  }


  searchItemByQuery(params) {

    const options=createQueryRequest(params);

    let url = `${this.baseUrl}/item/search`

    return this.httpGet({
      url:url,
      params:options,
      returnType: new GenericHttpResponse<Page<Item>>(new Page<Item>(Item)),
    }) as Observable<GenericHttpResponse<Page<Item[]>>>;

  }


}
