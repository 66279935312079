import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {
  Injectable,
  Injector
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, of, switchMap, throwError } from "rxjs";
import { yangoosStorageKeys } from "../constant/app.constant";
import { MycartDataService } from "../service/mycartData.service";

@Injectable()
export class YangoosHttpInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let jwtToken = localStorage.getItem(yangoosStorageKeys.authenticationToken);

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}` || "",
      },
    });

    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x, request, next)));
  }

  handleAuthError(err: HttpErrorResponse, request?, next?): Observable<any> {
    const userId = localStorage.getItem("userId");

    if (
      err.status === 401 &&
      err.error ===
        "User mail Account is Not Verified. So Complete Your Verification "
    ) {
      // this.router.navigateByUrl(`auth/otp/reVerify/`);
      return next.handle(request);
    } else if (err.status === 401 && userId) {
      let service = this.injector.get(MycartDataService);

      return service.refreshToken().pipe(
        switchMap((res: any) => {
          localStorage.setItem(
            yangoosStorageKeys.authenticationToken,
            res.accessToken
          );
          localStorage.setItem("refreshToken", res.refreshToken);

          const jwtToken = localStorage.getItem(
            yangoosStorageKeys.authenticationToken
          );
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwtToken}` || "",
            },
          });

          return next.handle(request);
        }),
        catchError((e) => {
          if (e.status !== 401) {
            return this.handleAuthError(e);
          } else {
          }
        })
      );
    } else if (err.status === 403) {
      let service = this.injector.get(MycartDataService);

      service.logout().subscribe({
        next: (res: any) => {
          localStorage.removeItem("userId");
          localStorage.clear();
          this.router.navigate(["/home/fashion"]);
        },
      });
    } else {
      return throwError(err);
    }

    return of("error...");
  }
}
