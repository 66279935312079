import * as moment from 'moment';
import { environment } from 'src/environments/environment';

export function momentTransform(value: any) {
  const date = moment(value);
  return date.isValid() ? date : null;
}

export function toDateTimeString(date: moment.Moment): string {
  return date.format(environment.dateTimeFormatISO);
}

export function toDateString(date: moment.Moment): string {
  return date.format(environment.dateFormatISO);
}

export function toDateTimeExportString(date: moment.Moment): string {
  return date.format(environment.dateTimeFormatFileName);
}
