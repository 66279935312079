import { Transform } from "class-transformer";
import { Moment } from "moment";
import { momentTransform } from "../utils/date.utils";

export class AbstractIdResponse{
    id: string;
}

export class AbstractResponse extends AbstractIdResponse{    
    createdBy: string;
    @Transform(({ value }) => momentTransform(value), { toClassOnly: true })
    createdDateTime: Moment;

}