import {
    AbstractResponse
} from "./abstract-response.model";

export class Item extends AbstractResponse {
  brandName: string;
  categoryName: string;
  currentOpeningStock: number;
  discount: number;
  expireDate: string;
  finalPrice: number;
  id: string;
  minimumQty: number;
  name: string;
  tax: string;
}

export class Items extends AbstractResponse {
  brandName: string;
  categoryName: string;
  currentOpeningStock: number;
  discount: number;
  expireDate: string;
  finalPrice: number;
  id: string;
  minimumQty: number;
  name: string;
  tax: string;
}

export class Itemview extends AbstractResponse {
  currentOpeningStock: number;
  discount: number;
  expireDate: string;
  finalPrice: number;
  id: string;
  minimumQty: number;
  name: string;
  store: {
    expiryDuration: string;
    id: string;
    name: string;
  };
}

export class Itemcategorylist extends AbstractResponse {
  brandName: string;
  categoryName: string;
  currentOpeningStock: number;
  discount: number;
  expireDate: string;
  finalPrice: number;
  id: string;
  minimumQty: number;
  name: string;
  tax: string;
}

export class GetAllSearch extends AbstractResponse {
  currentOpeningStock: number;
  finalPrice: number;
  minimumQty: number;
  name: string;
  unitIdealMeasurement: {
    abbreviation: string;
    code: string;
    description: string;
    id: string;
    name: string;
    sortOrder: number;
    unitType: {
      description: string;
      id: string;
      name: string;
    };
  };
}
