import { AbstractResponse } from "./abstract-response.model";

export class Country extends AbstractResponse {
  name: string;
  idc: string;
  code: string;
  active: boolean;
  postCodeLength: number;
  taxName: string;
}

export class CountryCreate {
  name: string;
  idc: string;
  code: string;
  postCodeLength: number;
  taxName: string;
}

export class CountryUpdate {
  id: string;
  name: string;
  idc: string;
  code: string;
  postCodeLength: number;
  taxName: string;
  active: boolean;
}

export const COUNTRY_COLUMNS = {
  NAME: "Name",
  IDC: "Idc",
  CODE: "Code",
  STATUS: "Status",
  POSTCODE_LENGTH: "PostCode Length",
  ACTIONS: "Actions",
};

export class CountryDatatable {
  id: string;
  name: string;
  idc: string;
  code: string;
  postCodeLength: string;
  active: boolean;
}
