import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { AbstractRestService } from "./abstract-rest.service";
import { environment } from "./../../../environments/environment";
import { Observable, Subject } from "rxjs";
import { GenericHttpResponse } from "../../core/models/generic-http-response.model";
import { Page } from "../../core/models/page.model";
import { Item } from "../models/item.model";
import {
  Mycart,
  MycartCreate,
  Mycartget,
  myCartEdit,
} from "../models/mycart-model";
@Injectable({
  providedIn: "root",
})
export class MycartService extends AbstractRestService {
  readonly baseUrl: string = environment.yangoosApiUrl + "/api";

  constructor(httpClient: HttpClient, loadingBarService: LoadingBarService) {
    super(httpClient, loadingBarService);
  }

  findById(
    id: string,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<Item>> {
    return this.httpGet({
      url: `${this.baseUrl}/item/${id}`,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Item>(Item),
    }) as Observable<GenericHttpResponse<Item>>;
  }

  save(
    create: MycartCreate,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<Mycart>> {
    return this.httpPost({
      url: `${this.baseUrl}/cartitem/create`,
      payload: create,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Mycart>(Mycart),
    }) as Observable<GenericHttpResponse<Mycart>>;
  }

  getAll(
    id = undefined,
    params = undefined,
    noLoadingMask = true
  ): Observable<GenericHttpResponse<Page<Mycartget[]>>> {
    let url = `${this.baseUrl}/cartitem/${id}`;
    return this.httpGet({
      url: url,
      //   params: options,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Page<Mycartget>>(
        new Page<Mycartget>(Mycartget)
      ),
    }) as Observable<GenericHttpResponse<Page<Mycartget[]>>>;
  }

  delete(
    ids: string,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<string>> {
    let params = new HttpParams();
    let url = `${this.baseUrl}/cartitem/delete/${ids}`;
    return this.httpDelete({
      url: url,
      params: params,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<String>(String),
    }) as Observable<GenericHttpResponse<string>>;
  }

  deleteAll(
    userId: string,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<string>> {
    let params = new HttpParams();
    let url = `${this.baseUrl}/cartitem/deleteByUserId/${userId}`;
    return this.httpDelete({
      url: url,
      params: params,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<String>(String),
    }) as Observable<GenericHttpResponse<string>>;
  }
  cartEdit(
    payload: myCartEdit,
    noLoadingMask = false
  ): Observable<GenericHttpResponse<Mycartget>> {
    let params = new HttpParams();
    let url = `${this.baseUrl}/cartitem/edit`;
    return this.httpPost({
      url: url,
      payload: payload,
      params: params,
      noLoadingMask: noLoadingMask,
      returnType: new GenericHttpResponse<Mycartget>(Mycartget),
    }) as Observable<GenericHttpResponse<Mycartget>>;
  }

  private mycartLength = new Subject<any>();

  private refreshMyCart = new Subject<any>();

  sendcartLength(length: any) {
    this.mycartLength.next(length);
  }

  getcartLength() {
    return this.mycartLength.asObservable();
  }

  isRefreshMyCart() {
    this.refreshMyCart.next(true);
  }

  receiveMyCartRefresh() {
    return this.refreshMyCart.asObservable();
  }
}
